import { Footer, Navbar } from "../../containers";
import React, { useEffect, useState, useRef } from "react";
import emailjs from "@emailjs/browser";
import $ from "jquery";
import HeaderHome from "../../containers/headerHome/HeaderHome";
import "./home.css";
import { PopupComponent } from "../../pages";
function Home() {
  // for faq height
  const [expandedElements, setExpandedElements] = useState({});

  const toggleHeight = (id) => {
    const elem = document.getElementById(id);
    const newExpanded = { ...expandedElements };
    if (!newExpanded[id]) {
      elem.style.height = "200px";
      newExpanded[id] = true;
    } else {
      elem.style.height = "auto";
      newExpanded[id] = false;
    }
    setExpandedElements(newExpanded);
  };
  //
  const [formType] = useState([
    {
      value: "Masters",
    },
    {
      value: "Undergraduate",
    },
    {
      value: "PHD",
    },
  ]);

  // For faq height
  const [vinsAdded, setVinsAdded] = useState({});

  const handleVinChange = (event) => {
    const selectedVin = event.target.value;
  };

  const [isModelOpen, setIsModelOpen] = useState(false);
  const [popupMessage, setPopupMessage] = useState("");

  const openModel = () => {
    window.location.href = "/thankyou";

    const form = assignmentForm.current;
    if (form.checkValidity()) {
      setPopupMessage(); // Set your desired message
      setIsModelOpen(true); // Open the popup message
    } else {
      form.reportValidity();
    }
  };

  const closeModel = () => {
    setIsModelOpen(false);
  };
  const assignmentForm = useRef();
  const sendEmail = (e) => {
    e.preventDefault();
    emailjs
      .sendForm(
        "service_4827scg",
        "template_7fnyq67",
        assignmentForm.current,
        "lcGODFCusPttwkCSV"
      )
      .then(
        (result) => {
          console.log(result.text);
          console.log("message sent");
          e.target.reset();
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  const [isContentVisible, setIsContentVisible] = useState(false);

  const toggleContent = () => {
    setIsContentVisible(!isContentVisible);
  };

  const exams = () => {
    document.getElementById("assignment").style.left = "-400px";
    document.getElementById("exams").style.left = "45px";
    document.getElementById("btn").style.left = "110px";
  };

  const assignment = () => {
    document.getElementById("assignment").style.left = "45px";
    document.getElementById("exams").style.left = "490px";
    document.getElementById("btn").style.left = "0px";
  };
  const report = () => {
    document.getElementById("assignment").style.left = "45px";
    document.getElementById("exams").style.left = "490px";
    document.getElementById("btn").style.left = "0px";
  };

  /*************** for assignment *******************/

  const buttonTexts = [
    [
      <ul style={{ lineHeight: 2 }}>
        <h1 style={{ fontSize: "32px", fontWeight: "400" }}>Nursing</h1>
        <li style={{ listStyle: "inside", listStyleType: "disc" }}>
          Guidance in choosing relevant and interesting nursing assignment
          topics.
        </li>
        <li style={{ listStyle: "inside", listStyleType: "disc" }}>
          Sample Papers: Access to sample nursing assignments for reference and
          guidance.
        </li>
        <li style={{ listStyle: "inside", listStyleType: "disc" }}>
          24/7 Support: Availability for queries and assistance around the
          clock.
        </li>
        <li style={{ listStyle: "inside", listStyleType: "disc" }}>
          Expert Consultation: Discussion with subject experts for complex
          nursing concepts or problems.
        </li>
      </ul>,
    ],
    [
      <ul style={{ lineHeight: 2 }}>
        <h1 style={{ fontSize: "32px", fontWeight: "400" }}>Management</h1>
        <li style={{ listStyle: "inside", listStyleType: "disc" }}>
          Become Pro At Time Management.
        </li>
        <li style={{ listStyle: "inside", listStyleType: "disc" }}>
          Ensure Top-Quality Submissions And Meet Deadlines.
        </li>
        <li style={{ listStyle: "inside", listStyleType: "disc" }}>
          Get Personalized Support And Expert Assistance For Academic Success.
        </li>
        <li style={{ listStyle: "inside", listStyleType: "disc" }}>
          Experienced Professionals Offer Specialized Guidance
        </li>
      </ul>,
    ],
    [
      <ul style={{ lineHeight: 2 }}>
        <h1 style={{ fontSize: "32px", fontWeight: "400" }}>Engineering</h1>
        <li style={{ listStyle: "inside", listStyleType: "disc" }}>
          Problem-solving Support: Assistance with complex engineering problems
          and calculations.
        </li>
        <li style={{ listStyle: "inside", listStyleType: "disc" }}>
          Software Tools: Guidance on using engineering software like MATLAB,
          AutoCAD, etc.
        </li>
        <li style={{ listStyle: "inside", listStyleType: "disc" }}>
          Research Resources: Access to engineering-specific databases,
          journals, and e-libraries.
        </li>
        <li style={{ listStyle: "inside", listStyleType: "disc" }}>
          Plagiarism Check: Tools to ensure engineering assignments are
          plagiarism-free and original.
        </li>
      </ul>,
    ],
    [
      <ul style={{ lineHeight: 2 }}>
        <h1 style={{ fontSize: "32px", fontWeight: "400" }}>Accounting</h1>
        <li style={{ listStyle: "inside", listStyleType: "disc" }}>
          Concept Explanation: Clarification and explanation of accounting
          principles and concepts.
        </li>
        <li style={{ listStyle: "inside", listStyleType: "disc" }}>
          Homework Assistance: Help with solving accounting problems or
          exercises.
        </li>
        <li style={{ listStyle: "inside", listStyleType: "disc" }}>
          Sample Financial Statements: Access to sample balance sheets, income
          statements, etc., for reference.
        </li>
        <li style={{ listStyle: "inside", listStyleType: "disc" }}>
          Expert Consultation: Discussion with accounting experts for complex
          topics or issues.
        </li>
      </ul>,
    ],
    [
      <ul style={{ lineHeight: 2 }}>
        <h1 style={{ fontSize: "32px", fontWeight: "400" }}>Medical</h1>
        <li style={{ listStyle: "inside", listStyleType: "disc" }}>
          Topic Guidance: Assistance in choosing relevant medical assignment
          topics.
        </li>
        <li style={{ listStyle: "inside", listStyleType: "disc" }}>
          Citation Assistance: Support in correctly citing medical sources
          following specific formatting styles (e.g., AMA, APA).
        </li>
        <li style={{ listStyle: "inside", listStyleType: "disc" }}>
          Custom Writing Services: Tailored assistance for essays, reports, or
          research papers based on specific medical requirements.
        </li>
        <li style={{ listStyle: "inside", listStyleType: "disc" }}>
          Proofreading & Editing: Services to refine medical assignments,
          focusing on accuracy and structure.
        </li>
      </ul>,
    ],
  ];

  const [textIndex, setTextIndex] = useState(0);

  useEffect(() => {
    // Set the initial background image when the component mounts
    const imageUrl = imageUrls[0]; // Get the first image URL
    const imgElement = document.querySelector(".aHSection3LeftImg");

    if (imgElement) {
      imgElement.style.backgroundImage = `url(${imageUrl})`;
      imgElement.style.backgroundSize = "cover";
      imgElement.style.backgroundPosition = "center";
    }
  }, []); // Empty dependency array ensures this runs only on mount

  const handleButtonClick = (index) => {
    setTextIndex(index);
    const imageUrl = imageUrls[index];
    const imgElement = document.querySelector(".aHSection3LeftImg");

    if (imgElement) {
      imgElement.style.backgroundImage = `url(${imageUrl})`;
      imgElement.style.backgroundSize = "cover";
      imgElement.style.backgroundPosition = "center";
    }
  };
  /****************Img****************/
  const imageUrls = [
    "https://images.unsplash.com/photo-1576669801945-7a346954da5a?auto=format&fit=crop&q=80&w=3270&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    "https://images.unsplash.com/photo-1538688423619-a81d3f23454b?auto=format&fit=crop&q=80&w=3270&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    "https://images.unsplash.com/photo-1581092160562-40aa08e78837?auto=format&fit=crop&q=80&w=3270&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    "https://images.unsplash.com/photo-1554224155-6726b3ff858f?auto=format&fit=crop&q=80&w=3411&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    "https://images.unsplash.com/photo-1576091358783-a212ec293ff3?auto=format&fit=crop&q=80&w=3000&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
  ];

  return (
    <>
      <Navbar />
      <HeaderHome />
      <div className="hero2">
        <div className="formBox2">
          {/* <div className="buttonBox">
          <div id="btn"></div>
          <button type="button" className="toggleBtn" onClick={assignment}>ASSIGNMENT</button>
          <button type="button" className="toggleBtn" onClick={exams}>EXAMS</button>
          <button type="button" className="toggleBtn">REPORT</button>
          <button type="button" className="toggleBtn">THESIS</button>
        </div> */}
          {/*  */}

          <div className="inputGroupp" id="assignment">
            <form
              ref={assignmentForm}
              onSubmit={sendEmail}
              style={{ position: "relative", top: "-70px" }}
            >
              <div className="formL1">
                <select
                  name="academic_level"
                  className="inputField"
                  placeholder="Select your Academic level"
                  id="vin"
                  onChange={handleVinChange}
                  required
                >
                  <option value="" disabled selected hidden>
                    Select Your Academic level
                  </option>{" "}
                  {formType.map((item, index) => {
                    if (!vinsAdded[item.value]) {
                      // Adding options to the select element
                      return (
                        <option key={index} value={item.value}>
                          {item.value}
                        </option>
                      );
                    }
                    return null;
                  })}
                </select>
              </div>
              <div className="formL2">
                <select name="domain" id="words_pages" className="inputField">
                  <option value="-- Please Select Domain --">
                    Select Your Domain
                  </option>
                  <option value="Accounting">Accounting &amp; Finance</option>
                  <option value="Advertising/Public Relations">
                    Advertising/Public Relations
                  </option>
                  <option value="Animal/Plant Biology">
                    Animal/Plant Biology
                  </option>
                  <option value="Anthropology">Anthropology</option>
                  <option value="Archaeology">Archaeology</option>
                  <option value="Architecture">Architecture</option>
                  <option value="Art">Art</option>
                  <option value="Biology">Biology</option>
                  <option value="Business">Business</option>
                  <option value="Chemistry">Chemistry</option>
                  <option value="Children &amp; Young People">
                    Children &amp; Young People
                  </option>
                  <option value="Civil">Civil</option>
                  <option value="Civil Litigation Law">
                    Civil Litigation Law
                  </option>
                  <option value="Commercial Law">Commercial Law</option>
                  <option value="Commercial Property Law">
                    Commercial Property Law
                  </option>
                  <option value="Communications">Communications</option>
                  <option value="Comparative/Conflict of Laws">
                    Comparative/Conflict of Laws
                  </option>
                  <option value="Competition Law">Competition Law</option>
                  <option value="Computer Science">Computer Science</option>
                  <option value="Construction">Construction</option>
                  <option value="Construction Law">Construction Law</option>
                  <option value="Contract Law">Contract Law</option>
                  <option value="Counselling">Counselling</option>
                  <option value="Criminal Law">Criminal Law</option>
                  <option value="Criminal Litigation (Law)">
                    Criminal Litigation (Law)
                  </option>
                  <option value="Criminology">Criminology</option>
                  <option value="Cultural Studies">Cultural Studies</option>
                  <option value="Dentistry">Dentistry</option>
                  <option value="Design">Design</option>
                  <option value="Drama">Drama</option>
                  <option value="Economics">Economics</option>
                  <option value="Economics (Social Sciences)">
                    Economics (Social Sciences)
                  </option>
                  <option value="Education">Education</option>
                  <option value="Employment">Employment</option>
                  <option value="Employment Law">Employment Law</option>
                  <option value="Engineering">Engineering</option>
                  <option value="English Language">English Language</option>
                  <option value="English Legal System (Law)">
                    English Legal System (Law)
                  </option>
                  <option value="English Literature">English Literature</option>
                  <option value="Environment">Environment</option>
                  <option value="Environmental Sciences">
                    Environmental Sciences
                  </option>
                  <option value="Environmental/Planning Law">
                    Environmental/Planning Law
                  </option>
                  <option value="Equity &amp; Trusts Law">
                    Equity &amp; Trusts Law
                  </option>
                  <option value="Estate Management">Estate Management</option>
                  <option value="European (EU) Law">European (EU) Law</option>
                  <option value="European Studies">European Studies</option>
                  <option value="Family Law">Family Law</option>
                  <option value="Fashion">Fashion</option>
                  <option value="Film Studies">Film Studies</option>
                  <option value="Finance">Finance</option>
                  <option value="Finance Law">Finance Law</option>
                  <option value="Food and Nutrition">Food and Nutrition</option>
                  <option value="Forensic Science">Forensic Science</option>
                  <option value="French">French</option>
                  <option value="General Law">General Law</option>
                  <option value="Geography">Geography</option>
                  <option value="Geology">Geology</option>
                  <option value="German">German</option>
                  <option value="Health">Health</option>
                  <option value="Health &amp; Social Care">
                    Health &amp; Social Care
                  </option>
                  <option value="Health and Safety">Health and Safety</option>
                  <option value="Health Psychology">Health Psychology</option>
                  <option value="History">History</option>
                  <option value="Hospitality">Hospitality</option>
                  <option value="Housing">Housing</option>
                  <option value="HRM">HRM</option>
                  <option value="Human Resource Management">
                    Human Resource Management
                  </option>
                  <option value="Human Rights">Human Rights</option>
                  <option value="Human Rights Law">Human Rights Law</option>
                  <option value="Immigration/Refugee Law">
                    Immigration/Refugee Law
                  </option>
                  <option value="Information Systems">
                    Information Systems
                  </option>
                  <option value="Information Technology">
                    Information Technology
                  </option>
                  <option value="Intellectual Property Law">
                    Intellectual Property Law
                  </option>
                  <option value="International Business">
                    International Business
                  </option>
                  <option value="International Commercial Law">
                    International Commercial Law
                  </option>
                  <option value="International Criminal Law">
                    International Criminal Law
                  </option>
                  <option value="International Law">International Law</option>
                  <option value="International Relations">
                    International Relations
                  </option>
                  <option value="International Studies">
                    International Studies
                  </option>
                  <option value="Jurisprudence (Law)">
                    Jurisprudence (Law)
                  </option>
                  <option value="Land/property Law">Land/property Law</option>
                  <option value="Landlord &amp; Tenant/Housing Law">
                    Landlord &amp; Tenant/Housing Law
                  </option>
                  <option value="Law">Law</option>
                  <option value="Law of Evidence">Law of Evidence</option>
                  <option value="Linguistics">Linguistics</option>
                  <option value="Literature">Literature</option>
                  <option value="Management">Management</option>
                  <option value="Maritime Law">Maritime Law</option>
                  <option value="Marketing">Marketing</option>
                  <option value="Maths">Maths</option>
                  <option value="Mechanical">Mechanical</option>
                  <option value="Media">Media</option>
                  <option value="Medical">Medical</option>
                  <option value="Medical Law">Medical Law</option>
                  <option value="Medicine">Medicine</option>
                  <option value="Mental Health">Mental Health</option>
                  <option value="Mental Health Law">Mental Health Law</option>
                  <option value="Methodology">Methodology</option>
                  <option value="Music">Music</option>
                  <option value="Nursing">Nursing</option>
                  <option value="Occupational Therapy">
                    Occupational Therapy
                  </option>
                  <option value="Oil &amp; Gas Law">Oil &amp; Gas Law</option>
                  <option value="Other">Other</option>
                  <option value="Others">Others</option>
                  <option value="Paramedic Studies">Paramedic Studies</option>
                  <option value="Pharmacology">Pharmacology</option>
                  <option value="Philosophy">Philosophy</option>
                  <option value="Photography">Photography</option>
                  <option value="Physical Education">Physical Education</option>
                  <option value="Physics">Physics</option>
                  <option value="Physiotherapy">Physiotherapy</option>
                  <option value="Politics">Politics</option>
                  <option value="Professional Conduct Law">
                    Professional Conduct Law
                  </option>
                  <option value="Psychology">Psychology</option>
                  <option value="Psychotherapy">Psychotherapy</option>
                  <option value="Public Administration">
                    Public Administration
                  </option>
                  <option value="Public Law">Public Law</option>
                  <option value="Quantity Surveying">Quantity Surveying</option>
                  <option value="Radiology &amp; Medical Technology">
                    Radiology &amp; Medical Technology
                  </option>
                  <option value="Real Estate">Real Estate</option>
                  <option value="Security &amp; Risk Management">
                    Security &amp; Risk Management
                  </option>
                  <option value="Social Policy">Social Policy</option>
                  <option value="Social Work">Social Work</option>
                  <option value="Social Work Law">Social Work Law</option>
                  <option value="Sociology">Sociology</option>
                  <option value="Spanish">Spanish</option>
                  <option value="Sports Law">Sports Law</option>
                  <option value="Sports Psychology">Sports Psychology</option>
                  <option value="Sports Science">Sports Science</option>
                  <option value="SPSS">SPSS</option>
                  <option value="Statistics">Statistics</option>
                  <option value="Tax Law">Tax Law</option>
                  <option value="Teacher Training / PGCE">
                    Teacher Training / PGCE
                  </option>
                  <option value="TESOL">TESOL</option>
                  <option value="Theatre Studies">Theatre Studies</option>
                  <option value="Theology &amp; Religion">
                    Theology &amp; Religion
                  </option>
                  <option value="Tort Law">Tort Law</option>
                  <option value="Tourism &amp; Hospitality">
                    Tourism &amp; Hospitality
                  </option>
                  <option value="Town &amp; Country Planning">
                    Town &amp; Country Planning
                  </option>
                  <option value="Translation">Translation</option>
                </select>
              </div>
              <div className="formL3">
                <select name="desired_service" className="inputField">
                  <option value="-- Please Select Desired Service --">
                    Select Your Desired Service
                  </option>
                  <option value="Assignment">Assignment</option>
                  <option value="Coursework">Coursework</option>
                  <option value="Dissertation Proposal">
                    Dissertation Proposal
                  </option>
                  <option value="Dissertation(Part)">Dissertation(Part)</option>
                  <option value="Dissertation(Full)">Dissertation(Full)</option>
                  <option value="Technical Research Dissertation">
                    Technical Research Dissertation
                  </option>
                  <option value="Essay Writing">Essay Writing</option>
                  <option value="Source Code with Technical Research Dissertation">
                    Source Code with Technical Research Dissertation
                  </option>
                  <option value="Only Source Code">Only Source Code</option>
                  <option value="Power Point Presentation">
                    Power Point Presentation
                  </option>
                  <option value="Power Point Presentation with Course Work">
                    Power Point Presentation with Course Work
                  </option>
                  <option value="Knowledge Transfer Sessions">
                    Knowledge Transfer Sessions
                  </option>
                  <option value="Only Tool">Only Tool</option>
                  <option value="Proof Reading">Proof Reading</option>
                  <option value="Proof Reading and Editing">
                    Proof Reading and Editing
                  </option>
                </select>
              </div>
              <div className="formL4">
                <select
                  name="words_pages"
                  id="words_pages"
                  className="inputField"
                >
                  <option value="-- Select No. of Words/Pages --">
                    Select No. of Words/Pages
                  </option>
                  <option value="500 words ~ 2 pages">
                    500 words ~ 2 pages
                  </option>
                  <option value="600 words ~ 2 pages">
                    600 words ~ 2 pages
                  </option>
                  <option value="900 words ~ 3 pages">
                    900 words ~ 3 pages
                  </option>
                  <option value="1200 words ~ 4 pages">
                    1200 words ~ 4 pages
                  </option>
                  <option value="1800 words ~ 6 pages">
                    1800 words ~ 6 pages
                  </option>
                  <option value="2400 words ~ 8 pages">
                    2400 words ~ 8 pages
                  </option>
                  <option value="3000 words ~ 10 pages">
                    3000 words ~ 10 pages
                  </option>
                  <option value="3600 words ~ 12 pages">
                    3600 words ~ 12 pages
                  </option>
                  <option value="4200 words ~ 14 pages">
                    4200 words ~ 14 pages
                  </option>
                  <option value="4800 words ~ 16 pages">
                    4800 words ~ 16 pages
                  </option>
                  <option value="5400 words ~ 18 pages">
                    5400 words ~ 18 pages
                  </option>
                  <option value="6000 words ~ 20 pages">
                    6000 words ~ 20 pages
                  </option>
                  <option value="6600 words ~ 22 pages">
                    6600 words ~ 22 pages
                  </option>
                  <option value="7200 words ~ 24 pages">
                    7200 words ~ 24 pages
                  </option>
                  <option value="7800 words ~ 26 pages">
                    7800 words ~ 26 pages
                  </option>
                  <option value="8400 words ~ 28 pages">
                    8400 words ~ 28 pages
                  </option>
                  <option value="9000 words ~ 30 pages">
                    9000 words ~ 30 pages
                  </option>
                  <option value="9600 words ~ 32 pages">
                    9600 words ~ 32 pages
                  </option>
                  <option value="10800 words ~ 36 pages">
                    10800 words ~ 36 pages
                  </option>
                  <option value="12000 words ~ 40 pages">
                    12000 words ~ 40 pages
                  </option>
                  <option value="13200 words ~ 44 pages">
                    13200 words ~ 44 pages
                  </option>
                  <option value="14400 words ~ 48 pages">
                    14400 words ~ 48 pages
                  </option>
                  <option value="15600 words ~ 52 pages">
                    15600 words ~ 52 pages
                  </option>
                  <option value="16800 words ~ 56 pages">
                    16800 words ~ 56 pages
                  </option>
                  <option value="18000 words ~ 60 pages">
                    18000 words ~ 60 pages
                  </option>
                  <option value="19200 words ~ 64 pages">
                    19200 words ~ 64 pages
                  </option>
                  <option value="20400 words ~ 68 pages">
                    20400 words ~ 68 pages
                  </option>
                  <option value="21600 words ~ 72 pages">
                    21600 words ~ 72 pages
                  </option>
                  <option value="22800 words ~ 76 pages">
                    22800 words ~ 76 pages
                  </option>
                  <option value="24000 words ~ 80 pages">
                    24000 words ~ 80 pages
                  </option>
                  <option value="25200 words ~ 84 pages">
                    25200 words ~ 84 pages
                  </option>
                  <option value="26400 words ~ 88 pages">
                    26400 words ~ 88 pages
                  </option>
                  <option value="27600 words ~ 92 pages">
                    27600 words ~ 92 pages
                  </option>
                  <option value="28800 words ~ 96 pages">
                    28800 words ~ 96 pages
                  </option>
                  <option value="30000 words ~ 100 pages">
                    30000 words ~ 100 pages
                  </option>
                </select>
              </div>
              <div className="formL5">
                <select name="tool" id="tool" className="inputField">
                  <option value="Select Tool">Select Tool</option>
                  <option value="None">None</option>
                  <option value="SPSS">SPSS</option>
                  <option value="STATA">STATA</option>
                  <option value="Eviews">Eviews</option>
                  <option value="Nvivo">Nvivo</option>
                  <option value="Xls Analysis">Xls Analysis</option>
                  <option value="Medical Tool">Medical Tool</option>
                  {/* Add more tool options here */}
                </select>
              </div>
              {/* <input
                  type="text"
                  name="user_name"
                  className="inputField"
                  placeholder="Enter Your Name"
                  required
                /> */}
              <input
                type="email"
                name="user_email"
                className="inputField"
                placeholder="webinfo@gmail.com"
                required
              />
              <div class="inputContainer">
                <select
                  id="countryCode"
                  name="countryCode"
                  class="inputFields inputFieldsss"
                >
                  <option value="+1">Dominican Republic (+1)</option>
                  <option value="+1">Barbados (+1)</option>
                  <option value="+1">Northern Mariana Island (+1)</option>
                  <option value="+1">Montserrat (+1)</option>
                  <option value="+1">Grenada (+1)</option>
                  <option value="+1">Bermuda (+1)</option>
                  <option value="+1">Turks & Caicos (+1)</option>
                  <option value="+1">Sint Maarten (+1)</option>
                  <option value="+1">Dominica (+1)</option>
                  <option value="+1">Puerto Rico (+1)</option>
                  <option value="+1">Bahamas (+1)</option>
                  <option value="+1">British Virgin Islands (+1)</option>
                  <option value="+1">Cayman Islands (+1)</option>
                  <option value="+1">Canada (+1)</option>
                  <option value="+1">Trinidad & Tobago (+1)</option>
                  <option value="+1">Antigua & Barbuda (+1)</option>
                  <option value="+1">St Kitts & Nevis (+1)</option>
                  <option value="+1">Anguilla (+1)</option>
                  <option value="+1">Jamaica (+1)</option>
                  <option value="+1">US Virgin Islands (+1)</option>
                  <option value="+1">St Lucia (+1)</option>
                  <option value="+1">American Samoa (+1)</option>
                  <option value="+1">St Vincent & the Grenadines (+1)</option>
                  <option value="+1">Guam (+1)</option>
                  <option value="+1">United States (+1)</option>
                  <option value="+7">Kazakhstan (+7)</option>
                  <option value="+7">Russia (+7)</option>
                  <option value="+20">Egypt (+20)</option>
                  <option value="+27">South Africa (+27)</option>
                  <option value="+31">Netherlands (+31)</option>
                  <option value="+32">Belgium (+32)</option>
                  <option value="+33">France (+33)</option>
                  <option value="+34">Spain (+34)</option>
                  <option value="+36">Hungary (+36)</option>
                  <option value="+39">Italy (+39)</option>
                  <option value="+40">Romania (+40)</option>
                  <option value="+41">Switzerland (+41)</option>
                  <option value="+43">Austria (+43)</option>
                  <option value="+44">United Kingdom (+44)</option>
                  <option value="+45">Denmark (+45)</option>
                  <option value="+46">Sweden (+46)</option>
                  <option value="+47">Norway (+47)</option>
                  <option value="+48">Poland (+48)</option>
                  <option value="+49">Germany (+49)</option>
                  <option value="+51">Peru (+51)</option>
                  <option value="+52">Mexico (+52)</option>
                  <option value="+53">Cuba (+53)</option>
                  <option value="+54">Argentina (+54)</option>
                  <option value="+55">Brazil (+55)</option>
                  <option value="+56">Chile (+56)</option>
                  <option value="+57">Colombia (+57)</option>
                  <option value="+58">Venezuela (+58)</option>
                  <option value="+60">Malaysia (+60)</option>
                  <option value="+61">Australia (+61)</option>
                  <option value="+62">Indonesia (+62)</option>
                  <option value="+631">Philippines (+63)</option>
                  <option value="+64">New Zealand (+64)</option>
                  <option value="+65">Singapore (+65)</option>
                  <option value="+661">Thailand (+66)</option>
                  <option value="+81">Japan (+81)</option>
                  <option value="+82">South Korea (+82)</option>
                  <option value="+84">Vietnam (+84)</option>
                  <option value="+86">China (+86)</option>
                  <option value="+90">Turkey (+90)</option>
                  <option value="+91">India (+91)</option>
                  <option value="+92">Pakistan (+92)</option>
                  <option value="+93">Afghanistan (+93)</option>
                  <option value="+94">Sri Lanka (+94)</option>
                  <option value="+95">Myanmar (+95)</option>
                  <option value="+98">Iran (+98)</option>
                  <option value="+211">South Sudan (+211)</option>
                  <option value="+212">Western Sahara (+212)</option>
                  <option value="+212">Morocco (+212)</option>
                  <option value="+213">Algeria (+213)</option>
                  <option value="+216">Tunisia (+216)</option>
                  <option value="+218">Libya (+218)</option>
                  <option value="+220">Gambia (+220)</option>
                  <option value="+221">Senegal (+221)</option>
                  <option value="+222">Mauritania (+222)</option>
                  <option value="+223">Mali (+223)</option>
                  <option value="+224">Guinea (+224)</option>
                  <option value="+225">Ivory Coast (+225)</option>
                  <option value="+226">Burkina Faso (+226)</option>
                  <option value="+227">Niger (+227)</option>
                  <option value="+228">Togo (+228)</option>
                  <option value="+229">Benin (+229)</option>
                  <option value="+230">Mauritius (+230)</option>
                  <option value="+231">Liberia (+231)</option>
                  <option value="+232">Sierra Leone (+232)</option>
                  <option value="+233">Ghana (+233)</option>
                  <option value="+234">Nigeria (+234)</option>
                  <option value="+235">Chad (+235)</option>
                  <option value="+236">Central African Republic (+236)</option>
                  <option value="+237">Cameroon (+237)</option>
                  <option value="+238">Cape Verde (+238)</option>
                  <option value="+239">São Tomé and Príncipe (+239)</option>
                  <option value="+240">Equatorial Guinea (+240) </option>
                  <option value="+241">Gabon (+241)</option>
                  <option value="+242">Congo (+242)</option>
                  <option value="+243">
                    Democratic Republic of the Congo (+243)
                  </option>
                  <option value="+245">Guinea-Bissau (+245)</option>
                  <option value="+246">
                    British Indian Ocean Territory (+246)
                  </option>
                  <option value="+247">Ascension Island (+247)</option>
                  <option value="+248">Seychelles (+248)</option>
                  <option value="+249">Sudan (+249)</option>
                  <option value="+250">Rwanda (+250) </option>
                  <option value="+251">Ethiopia (+251)</option>
                  <option value="+253">Djibouti (+253) </option>
                  <option value="+254">Kenya (+254)</option>
                  <option value="+255">Tanzania (+255)</option>
                  <option value="+256">Uganda (+256)</option>
                  <option value="+257">Burundi (+257) </option>
                  <option value="+258">Mozambique (+258)</option>
                  <option value="+260">Zambia (+260)</option>
                  <option value="+261">Madagascar (+261)</option>
                  <option value="+262">Réunion (+262)</option>
                  <option value="+263">Zimbabwe (+263)</option>
                  <option value="+264">Namibia (+264) </option>
                  <option value="+265">Malawi (+265) </option>
                  <option value="+266">Lesotho (+266)</option>
                  <option value="+267">Botswana (+267) </option>
                  <option value="+268">Swaziland (+268)</option>
                  <option value="+269">Comoros (+269) </option>
                  <option value="+290">
                    Saint Helena and Tristan da Cunha (+290)
                  </option>
                  <option value="+291">Eritrea (+291) </option>
                  <option value="+297">Aruba (+297) </option>
                  <option value="+298">Faroe Islands (+298) </option>
                  <option value="+299">Greenland (+299) </option>
                  <option value="+350">Gibraltar (+350) </option>
                  <option value="+351">Portugal (+351) </option>
                  <option value="+352">Luxembourg (+352) </option>
                  <option value="+353">Ireland (+353)</option>
                  <option value="+354">Iceland (+354)</option>
                  <option value="+355">Albania (+355)</option>
                  <option value="+356">Malta (+356)</option>
                  <option value="+357">Cyprus (+357)</option>
                  <option value="+358">Finland (+358)</option>
                  <option value="+359">Bulgaria (+359)</option>
                  <option value="+370">Lithuania (+370)</option>
                  <option value="+371">Latvia (+371) </option>
                  <option value="+372">Estonia (+372)</option>
                  <option value="+373">Moldova (+373) </option>
                  <option value="+374">Armenia (+374)</option>
                  <option value="+375">Belarus (+375) </option>
                  <option value="+376">Andorra (+376) </option>
                  <option value="+377">Monaco (+377) </option>
                  <option value="+378">San Marino (+378) </option>
                  <option value="+380">Ukraine (+380) </option>
                  <option value="+381">Serbia (+381) </option>
                  <option value="+383">Kosovo (+383) </option>
                  <option value="+385">Croatia (+385) </option>
                  <option value="+386">Slovenia (+386) </option>
                  <option value="+387">Bosnia-Herzegovina (+387) </option>
                  <option value="+389">Macedonia (+389) </option>
                  <option value="+420">Czech Republic (+420) </option>
                  <option value="+421">Slovakia (+421) </option>
                  <option value="+423">Liechtenstein (+423) </option>
                  <option value="+500">Falkland Islands (+500) </option>
                  <option value="+501">Belize (+501) </option>
                  <option value="+502">Guatemala (+502) </option>
                  <option value="+503">El Salvador (+503) </option>
                  <option value="+504">Honduras (+504) </option>
                  <option value="+505">Nicaragua (+505) </option>
                  <option value="+506">Costa Rica (+506)</option>
                  <option value="+507">Panama (+507) </option>
                  <option value="+508">
                    Saint Pierre and Miquelon (+508){" "}
                  </option>
                  <option value="+509">Haiti (+509) </option>
                  <option value="+590">Guadeloupe (+590) </option>
                  <option value="+591">Bolivia (+591) </option>
                  <option value="+592">Guyana (+592) </option>
                  <option value="+593">Ecuador (+593) </option>
                  <option value="+594">French Guiana (+594)</option>
                  <option value="+595">Paraguay (+595) </option>
                  <option value="+596">Martinique (+596) </option>
                  <option value="+597">Suriname (+597) </option>
                  <option value="+598">Uruguay (+598) </option>
                  <option value="+599">Curaçao (+599) </option>
                  <option value="+599">Caribbean Netherlands (+599) </option>
                  <option value="+670">Timor-Leste (+670) </option>
                  <option value="+672">
                    Australian Antarctic Territory (+672)
                  </option>
                  <option value="+673">Brunei (+673) </option>
                  <option value="+674">Nauru (+674) </option>
                  <option value="+675">Papua New Guinea (+675) </option>
                  <option value="+676">Tonga (+676) </option>
                  <option value="+677">Solomon Islands (+677) </option>
                  <option value="+678">Vanuatu (+678) </option>
                  <option value="+679">Fiji (+679) </option>
                  <option value="+680">Palau (+680) </option>
                  <option value="+681">Wallis and Futuna (+681) </option>
                  <option value="+682">Cook Islands (+682) </option>
                  <option value="+683">Niue (+683) </option>
                  <option value="+685">Samoa (+685) </option>
                  <option value="+686">Kiribati (+686) </option>
                  <option value="+687">New Caledonia (+687) </option>
                  <option value="+688">Tuvalu (+688) </option>
                  <option value="+689">French Polynesia (+689) </option>
                  <option value="+382">Tokelau (+690) (+382) </option>
                  <option value="+691">Micronesia (+691) </option>
                  <option value="+692">Marshall Islands (+692) </option>
                  <option value="+850">North Korea (+850) </option>
                  <option value="+852">Hong Kong (+852) </option>
                  <option value="+853">Macau (+853) </option>
                  <option value="+855">Cambodia (+855) </option>
                  <option value="+856">Laos (+856) </option>
                  <option value="+880">Bangladesh (+880) </option>
                  <option value="+960">Maldives (+960) </option>
                  <option value="+961">Lebanon (+961) </option>
                  <option value="+962">Jordan (+962) </option>
                  <option value="+963">Syria (+963) </option>
                  <option value="+964">Iraq (+964) </option>
                  <option value="+965">Kuwait (+965) </option>
                  <option value="+966">Saudi Arabia (+966) </option>
                  <option value="+968">Oman (+968) </option>
                  <option value="+970">Palestine (+970) </option>
                  <option value="+971">United Arab Emirates (+971) </option>
                  <option value="+972">Israel (+972) </option>
                  <option value="+973">Bahrain (+973) </option>
                  <option value="+974">Qatar (+974) </option>
                  <option value="+975">Bhutan (+975) </option>
                  <option value="+976">Mongolia (+976) </option>
                  <option value="+977">Nepal (+977) </option>
                  <option value="+992">Tajikistan (+992) </option>
                  <option value="+993">Turkmenistan (+993) </option>
                  <option value="+994">Azerbaijan (+994) </option>
                  <option value="+995">Georgia (+995) </option>
                  <option value="+996">Kyrgyzstan (+996) </option>
                  <option value="+998">Uzbekistan (+998) </option>
                </select>
                <input
                  type="text"
                  name="mobile_no"
                  class="inputFieldsssss inputField"
                  placeholder="Enter Mobile No."
                  required
                />
              </div>
              {/* <input
                  type="text"
                  name="subject_name"
                  className="inputField"
                  placeholder="Subject Name"
                  required
                /> */}
              <input
                type="date"
                name="date"
                className="inputField"
                placeholder="dd/mm/yy"
                required
              />
              {/* <label
                  htmlFor="fileUpload"
                  className="inputField inputFieldd"
                  style={{ fontSize: "14px" }}
                >
                  Assignment Files Upload
                </label> */}
              {/* <input
                  type="file"
                  name="assignmentFile"
                  id="fileUpload"
                  style={{ display: "none" }}
                  className="inputField inputFieldd"
                  required
                />
                <textarea
                  name="message"
                  className="inputField"
                  placeholder="Message"
                  required
                /> */}

              {/* <input type="submit" className="startBtn" value="Let's Start +" /> */}
              <div>
                {/* Button to trigger the PopupComponent */}
                <button onClick={openModel} className="startBtn">
                  Let's Start +
                </button>

                {/* PopupComponent rendered conditionally based on isModelOpen */}
                {isModelOpen && (
                  <PopupComponent
                    isOpen={isModelOpen}
                    closePopup={closeModel}
                    message={popupMessage}
                  />
                )}
              </div>
            </form>
          </div>
          {/* <div className="inputGroupp" id="exams">
          <input type="text" className="inputField" placeholder="Exam" required />
          <input type="email" className="inputField" placeholder="exam@gmail.com" required />
          <input type="text" className="inputField" placeholder="Exam Name" required />
          <input type="date" className="inputField" placeholder="dd/mm/yy" required />
          <input type="text" className="inputField" placeholder="Exam Files Upload" required />
          <input type="text" className="inputField" placeholder="Examm" required />
          <button type="button" className="startBtn">Submit</button>
        </div><div className="inputGroupp" id="report">
          <input type="text" className="inputField" placeholder="Exam" required />
          <input type="email" className="inputField" placeholder="exam@gmail.com" required />
          <input type="text" className="inputField" placeholder="Exam Name" required />
          <input type="date" className="inputField" placeholder="dd/mm/yy" required />
          <input type="text" className="inputField" placeholder="Exam Files Upload" required />
          <input type="text" className=""d placeholder="Examm" required />
          <button type="button" className="startBtn">Submit</button>
        </div> */}
        </div>
      </div>
      <div className="our-services2">
        <div className="title99">
          <div className="expart-team1">EXCELLENT SERVICE</div>
          <b className="get-only-new-container2">
            <p className="about-us56">Get Only New And Unique Boots Your</p>
            <p className="about-us56">CourseWorkZone Online Learning!</p>
          </b>
        </div>
        <div className="innerServices59">
          <div className="service-133">
            <div
              className="innerServices59contenth"
              style={{ fontSize: "18px" }}
            >
              Dissertation Writing Service
            </div>
            {/* <img className="mask-group-icon41" alt="" src="/mask-group6@2x.png" /> */}
            <div className="innerServices59content">{`Courseworkzone, the UK's sole dissertation writing service, consistently earns the "No.1 Dissertation Writing Service" title from major review platforms like Google, TrustPilot, Sitejabber, Yell, and Yelp, boasting perfect 5-star ratings. Their unwavering commitment to client success and satisfaction solidifies their position as the most beloved and trusted service in the UK, proud of their achievements and impeccable track record.`}</div>
          </div>
          <div className="service-233">
            <div
              className="innerServices59contenth2 innerServices59contenth22"
              style={{ fontSize: "18px" }}
            >
              Thesis Writing Help
            </div>
            {/* <img className="mask-group-icon41" alt="" src="/mask-group7@2x.png" /> */}
            <div className="innerServices59content innerServices59content2">{`Our PhD thesis writing services involve preparing a proposal, crafting an engaging introduction, conducting literature reviews, research design, and presenting results. Clients can participate at every stage with intermediate drafts. Making a unique contribution to existing knowledge is challenging, requiring identifying gaps in literature and conducting relevant research.`}</div>
          </div>
          <div className="service-3333">
            <div
              className="innerServices59contenth2 innerServices59contenth23"
              style={{ fontSize: "18px" }}
            >{`Coursework Help`}</div>
            {/* <img className="mask-group-icon43" alt="" src="/mask-group8@2x.png" /> */}
            <div className="innerServices59content">{`For 11 years, Courseworkzone has offered superior academic writing services to numerous students through experienced writers. We are dedicated to matching you with the most suitable professional and upholding strict ethics, ensuring you receive the coursework grades you rightfully deserve from universities.`}</div>
          </div>
        </div>
        <div className="companiessss">
          <img
            src="https://cwz-git-main-harshitasparrowtech-gmailcom.vercel.app/colleges.jpg"
            alt=""
          />
        </div>
        <div className="stats">
          <div className="stat1">
            <div className="digit">1.2K+</div>
            <div className="text">Total Tutorials</div>
          </div>
          <div className="stat1">
            <div className="digit digit2">7.3K+</div>
            <div className="text">Total Students</div>
          </div>
          <div className="stat1">
            <div className="digit digit2">1.2K+</div>
            <div className="text">Total Teacher</div>
          </div>
          <div className="stat1">
            <div className="digit digit2">9.5K+</div>
            <div className="text">Total Visitors</div>
          </div>
        </div>

        <div className="assignmentContainer">
          <div className="background">
            <div className="imageUpper"></div>
            <div className="imageLower"></div>
          </div>
          <div className="contentWrapper">
            <div className="contentHeading">
              <div className="headingUpper">EXCELLENT COURSES</div>
              <div className="headingLower">Exceptional Online Assignment</div>
            </div>
            <div className="cardssssss">
              <div className="cards1">
                <div className="cardElementAssignment bor1">
                  <div className="headingCardElement">
                    Easy Writing Guidance.
                  </div>
                  <div className="cardContent">
                    Providing Straightforward And User-Friendly Writing Guidance
                    To Make The Process Effortless And Enjoyable, Helping You
                    Achieve Clarity And Effectiveness In Your Writing Endeavors.
                  </div>
                </div>
                <div className="cardElementAssignment bor1 padd padd0">
                  <div className="headingCardElement">
                    Dissertation Writing Guidance.
                  </div>
                  <div className="cardContent">
                    Expert Dissertation Writing Guidance: From Proposal To
                    Literature Review, Research Design, And Result Presentation.
                    Ensuring A Well-Crafted And Successful Dissertation With
                    Professional Support And Assistance.
                  </div>
                </div>
              </div>
              <div className="cards2">
                <div className="cardElementAssignment  bor1 ">
                  <div className="headingCardElement">
                    Easy Writing Assignment.
                  </div>
                  <div className="cardContent">
                    A User-Friendly Writing Assignment Designed With Clear
                    Instructions And Helpful Resources, Ensuring A Smooth And
                    Enjoyable Experience While Fostering Creativity And
                    Effective Communication.
                  </div>
                </div>
                <div className="cardElementAssignment padd padds bor1 padd1">
                  <div className="headingCardElement">
                    Online Thesis Guidance.
                  </div>
                  <div className="cardContent">
                    <p>
                      Convenient Online Thesis Guidance, Providing Expert
                      Support And Resources For Every Step Of The Thesis Writing
                      Process, Ensuring A Successful And Well-Structured Thesis.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="assignmentHelpSection">
          <div className="aHWrapper">
            <div className="aHWrapperHeading">
              <div className="aHWrapperHeadingUpper">EXAM HELP</div>
              <div className="aHWrapperHeadingLower">
                Online Assignment Help
              </div>
            </div>
            <div className="aHWrapperbuttons">
              <div className="okokok">
                <button onClick={() => handleButtonClick(0)} id="button1">
                  Nursing
                </button>
                <button onClick={() => handleButtonClick(1)} id="button2">
                  Management
                </button>
                <button onClick={() => handleButtonClick(2)} id="button3">
                  Engineering
                </button>
                <button onClick={() => handleButtonClick(3)} id="button4">
                  Accounting
                </button>
                <button onClick={() => handleButtonClick(4)} id="button5">
                  Medical
                </button>
              </div>
            </div>
          </div>
          <div className="aHSection3">
            <div className="aHSection3Left">
              <div className="aHSection3LeftImg"></div>
            </div>
            <div className="aHSection3Right">
              <div className="aHSection3RightContent">
                <div className="text-change">{buttonTexts[textIndex]}</div>
              </div>
            </div>
          </div>
        </div>
        <div className="testimonial">
          <div className="testiHead">
            <div className="heading">
              <h1>Testimonial</h1>
            </div>
            <div className="testiHImg"></div>
          </div>
          <div className="testiContent">
            <p>
              "CourseWorkZone's Exceptional Service Helped Me Secure Top Grades
              In My Coursework. The Experts Were Knowledgeable And Delivered On
              Time. Highly Recommended!" - Sarah P.
            </p>
          </div>
        </div>

        <div className="faq">
          <div className="faqHeading">
            <div className="faqUHead">OUR FAQ</div>
            <div className="faqLHead">
              Frequently Asked <br /> Questions
            </div>
          </div>
          <div className="faqContent">
            <div className="faqLeft">
              <div className="leftContainer">
                <div className="elem1" id="elem1_1">
                  <button
                    className="elem1Heading"
                    onClick={() => toggleHeight("elem1_1")}
                  >
                    Can courseworkzone guarantee that my essay will be
                    plagiarism-free?
                  </button>
                  <div className="elem1content">
                    <p className="faqP">
                      Yes, we guarantee that all of our essays are
                      plagiarism-free at CourseWorkZone. We understand the
                      importance of producing original work. Final work is
                      submitted with Turnitin report.
                    </p>
                  </div>
                </div>
                <div className="elem1" id="elem1_2">
                  <button
                    className="elem1Heading"
                    onClick={() => {
                      toggleContent();
                      toggleHeight("elem1_2");
                    }}
                  >
                    Can courseworkzone guarantee that my essay will be
                    plagiarism-free?
                  </button>
                  <div className="elem1content">
                    <p className="faqP">
                      Yes, we guarantee that all of our essays are
                      plagiarism-free at CourseWorkZone. We understand the
                      importance of producing original work. Final work is
                      submitted with Turnitin report.
                    </p>
                  </div>
                </div>
                <div className="elem1" id="elem1_3">
                  <button
                    className="elem1Heading"
                    onClick={() => toggleHeight("elem1_3")}
                  >
                    Can courseworkzone guarantee that my essay will be
                    plagiarism-free?
                  </button>
                  <div className="elem1content">
                    <p className="faqP">
                      Yes, we guarantee that all of our essays are
                      plagiarism-free at CourseWorkZone. We understand the
                      importance of producing original work. Final work is
                      submitted with Turnitin report.
                    </p>
                  </div>
                </div>
                <div className="elem1" id="elem1_4">
                  <button
                    className="elem1Heading"
                    onClick={() => toggleHeight("elem1_4")}
                  >
                    Can courseworkzone guarantee that my essay will be
                    plagiarism-free?
                  </button>
                  <div className="elem1content">
                    <p className="faqP">
                      Yes, we guarantee that all of our essays are
                      plagiarism-free at CourseWorkZone. We understand the
                      importance of producing original work. Final work is
                      submitted with Turnitin report.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="faqRight">
              <div className="faqRightelem">
                <img
                  src="https://img.freepik.com/free-vector/faqs-concept-illustration_114360-5185.jpg?w=1480&t=st=1698751328~exp=1698751928~hmac=52baf291070d7d711748cdde821b09bf5636cf96064b2d232e235277feb68f6c"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
export default Home;
