import React from "react";
import "./headerServices.css";
import image3 from "../../assets/image3.webp";
const HeaderServices = () => {
  return (
    <div className="mainService">
      <div
        className="sectionContact"
        style={{ backgroundImage: `url(${image3})`, display: "flex" }}
      >
        {" "}
        <div className="section68"></div>
        <div className="section2"></div>
      </div>
    </div>
  );
};

export default HeaderServices;
