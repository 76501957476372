import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { About, Contact, Home, Offers, Service } from "./pages";
import { Footer, Header, Navbar } from "./containers";
import { Helmet } from "react-helmet";
import { Analytics } from "@vercel/analytics/react";

import "./App.css";

const App = () => {
  return (
    <div className="App">
      <Helmet>
        <meta charSet="utf-8" />
        <title>CourseWorkZone</title>
        <link rel="canonical" href="http://mysite.com/example" />
        <meta name="description" content="Testing" />
      </Helmet>
      <Home />
      <Analytics />
    </div>
  );
};

export default App;
