import React from "react";
import { WhatsAppOutlined } from "@ant-design/icons";
import { PhoneOutlined } from "@ant-design/icons";
import "./footer.css";

const Footer = () => {
  return (
    <div className="footer">
      <div className="footerContainer">
        <div className="containerHeading">
          <div className="HeadingLogo">
            <img
              src="https://cwz-git-main-harshitasparrowtech-gmailcom.vercel.app/mask-group17@2x.png"
              alt=""
            />
          </div>
          <div className="headingLogoText">CourseWorkZone</div>
        </div>
        <div className="containerCards">
          <div className="link2">
            <div className="link2Head">Contacts</div>
            <div className="link2Li">
              <p className="green">Email:</p>
              <p className="fsize">ceo@courseworkzone.co.uk</p>
              <p className="green">Phone:</p>
              <p style={{ width: "150px" }}>
                <span style={{marginRight:"6px"}}>
                  <PhoneOutlined />
                </span>
                02039899999 <br />
                <img
                  style={{ width: "12px", height: "12px", marginRight: "4px" }}
                  src="https://w7.pngwing.com/pngs/713/815/png-transparent-whatsapp-iphone-whatsapp-logo-monochrome-black-thumbnail.png"
                  alt="WhatsApp Icon"
                />
                +447862200893
              </p>
            </div>
          </div>

          <div className="link3">
            <div className="link3Head">UseFul Links</div>
            <div className="link3Li">
              <a href="/about"> About us</a>
              <a href="/service">Services</a>
              <a href="/offers">Our Pricing</a>
              <a href="/privacy">Privacy Policy</a>
            </div>
          </div>
          <div className="link4">
            <div className="link4Li">
              <div className="link4Li1">
                <div className="link4Li1Logo">
                  <img
                    src="https://cwz-git-main-harshitasparrowtech-gmailcom.vercel.app/teams/dominic-withers.jpeg"
                    alt=""
                  />
                </div>
                <div className="link4Li1Content">
                  Subject Matter Experts And Writers
                </div>
              </div>
              <div className="link4Li2">
                <div className="link4Li2Logo">
                  <img
                    src="https://cwz-git-main-harshitasparrowtech-gmailcom.vercel.app/teams/michael-shores.jpeg"
                    alt=""
                  />
                </div>
                <div className="link4Li2Content">
                  Money Back Garranty and Rouls
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="containerFooter">
        Copywright by @courseworkzone. All rights reserved 2023.
      </div>
    </div>
  );
};

export default Footer;
