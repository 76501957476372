import React, { useState, useEffect } from "react";
import previe from "../../assets/previe.png";
import "./navbar.css";
import $ from "jquery"; // Make sure you have jQuery installed
import { WhatsAppOutlined } from "@ant-design/icons";

const Navbar = () => {
  // For responsive navbar
  const [isDropdownActive, setIsDropdownActive] = useState(false);

  const toggleDropdown = () => {
    setIsDropdownActive(!isDropdownActive);
  };

  const links = [
    { text: "Home", url: "/home" },
    { text: "Service", url: "/service" },
    { text: "Job", url: "/job" },
    { text: "Admission", url: "/admission" },
    { text: "Offer", url: "/offers" },
    { text: "About", url: "/about" },
    { text: "Contact Us", url: "/contact" },
    // Add other links here in a similar format
  ];

  const [isActive, setIsActive] = useState(false);

  // Mobile menu handling
  const mobileMenu = () => {
    setIsActive(!isActive);
  };

  const closeMenu = () => {
    setIsActive(false);
  };
  useEffect(() => {
    const changeNavbarColor = () => {
      const scroll = $(window).scrollTop();
      const navbar = document.getElementById("navbar");

      if (scroll > 100) {
        $(navbar).css("background", "#7f53ac");
      } else {
        $(navbar).css("background", "transparent");
      }
    };

    window.addEventListener("scroll", changeNavbarColor);

    return () => {
      window.removeEventListener("scroll", changeNavbarColor);
    };
  }, []);

  return (
    <div className="navbar">
      <div className="mainLogo">
        <img src={previe} alt="logo" />
      </div>
      <div className="contact">
        <div className="mailLogo">
          <p>ceo@courseworkzone.co.uk</p>
        </div>
        <div className="needHelp">
          <p>
            Need help ? Talk to an expert:
            <br />
            02039899999 / <WhatsAppOutlined />
            +447862200893
          </p>
        </div>
      </div>
      <div className="links">
        <div className="link">
          <a href="/home">
            <img
              src="https://cwz-git-main-harshitasparrowtech-gmailcom.vercel.app/home.png"
              alt=""
            />
          </a>
          <a href="/home">Home</a>
        </div>
        <div className="link linkimg">
          <a href="/service">
            <img
              src="https://cdn-icons-png.flaticon.com/512/4526/4526832.png"
              alt=""
            />
          </a>
          <a href="/service">Service</a>
        </div>

        <div className="link linkimg">
          <a href="/job">
            <img
              src="https://cdn-icons-png.flaticon.com/512/2910/2910791.png"
              alt=""
            />
          </a>
          <a href="/job">Job</a>
        </div>
        <div className="link linkimg">
          <a href="/admission">
            <img
              src="https://cdn-icons-png.flaticon.com/512/3596/3596097.png"
              alt=""
            />
          </a>
          <a href="/admission">Admission</a>
        </div>
        <div className="link linkimg">
          <a href="/offers">
            <img
              src="https://cdn-icons-png.flaticon.com/512/879/879859.png"
              alt=""
            />
          </a>
          <a href="/offers">Offer</a>
        </div>
        <div className="link linkimg">
          <a href="/about">
            <img
              src="https://cdn-icons-png.flaticon.com/512/64/64494.png"
              alt=""
            />
          </a>
          <a href="/about">About</a>
        </div>
        <div className="link linkimg">
          <a href="/contact">
            <img
              src="https://cdn-icons-png.flaticon.com/512/3249/3249904.png"
              alt=""
            />
          </a>
          <a href="/contact">Contact Us</a>
        </div>
        <div className="hamburger" onClick={toggleDropdown}>
          <div
            style={{
              width: "30px",
              height: "3px",
              backgroundColor: "white",
              margin: "5px 0px",
            }}
          ></div>
          <div
            style={{
              width: "30px",
              height: "3px",
              backgroundColor: "white",
              margin: "5px 0px",
            }}
          ></div>
          <div
            style={{
              width: "30px",
              height: "3px",
              backgroundColor: "white",
              margin: "5px 0px",
            }}
          ></div>
        </div>
        <ul className={`nav-menu ${isDropdownActive ? "active" : ""}`}>
          {links.map((link, index) => (
            <li
              key={index}
              style={{ display: isDropdownActive ? "block" : "none" }}
            >
              <a href={link.url}>{link.text}</a>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default Navbar;
