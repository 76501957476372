import React from "react";
import HeaderJob from "../../containers/headerJob/HeaderJob";
import { Footer, Navbar } from "../../containers";
import "./job.css";

const Job = () => {
  return (
    <>
      <Navbar />
      {/* <HeaderJob/> */}
      <div className="admissionWrapper">
        <div
          className="section1Adm"
          style={{
            backgroundImage: `url('https://images.ctfassets.net/7qqwgjna58ct/6jeM1Fy2NFYccVXJ8GcKp8/19fdc0987e380e4a32bc155b460a90c6/Object-min.png')`,
            backgroundSize: "cover",
            mixBlendMode: "color-burn",
          }}
        >
          <div className="section1AdmRight">
            <h1>What's next...</h1>
            <p>
              We're here to help you take your next step in the world of work,
              wherever or whatever it is.
            </p>
            <div className="button">
              <button className="role role1">Find a Role</button>
              {/* <button className='role role2'>Find a Role</button> */}
            </div>
          </div>

          <div className="section1AdmLeft"></div>
        </div>
        <div className="section2Adm">
          <div className="section2Adm1">
            <h1>Our sectors of expertise</h1>
          </div>
          <div className="section2Adm2">
            <p style={{ fontSize: "18px" }}>
              Since 2017 CourseWorkZone has pioneered specialist recruitment,
              sourcing knowledgeable, skilled professionals for jobs across the
              UK. Our experts recruit across 20 sectors, so whether you are
              looking to hire your next head of finance, or urgently require
              supply teachers, we can help you.
            </p>
          </div>
        </div>
        <div className="section3Adm">
          <div className="section3AdmWrapper">
            <div className="section3Adm1">
              <div className="section3Adm11">
                <div className="section3Adm11Logo">
                  <div className=" img-innerrrr">
                    <img
                      src="https://www.reed.com/_next/image?url=https%3A%2F%2Fimages.ctfassets.net%2F7qqwgjna58ct%2F4CUfdNVbNNksPbtX89Pb2u%2F95a82520f5b75bd3d55709ccf150fa5c%2FemployerAccountancyFinanceSector-min.jpg&w=3840&q=75"
                      alt=""
                    />
                  </div>
                </div>
                <div className="section3Adm11Content">
                  <div className="section3Adm11ContentHeading">
                    Accountancy & finance
                  </div>
                  <div className="section3Adm11ContentP">
                    <p>
                      CourseWorkZone's consultants are experienced in the
                      accountancy and finance recruitment market, with each
                      member of our team specialising across sectors and
                      qualification levels.
                    </p>
                  </div>
                </div>
              </div>
              <div className="section3Adm12">
                <div className="section3Adm11Logo">
                  <div className=" img-innerrrr">
                    <img
                      src="https://www.reed.com/_next/image?url=https%3A%2F%2Fimages.ctfassets.net%2F7qqwgjna58ct%2Frk6Oi8jbfnftQBx4Fmxgv%2Fa5a7a014f91d81058f2bc675ce0037d6%2FBusiness-support-hero-small.jpg&w=3840&q=75"
                      alt=""
                    />
                  </div>
                </div>
                <div className="section3Adm11Content">
                  <div className="section3Adm12ContentHeading">
                    Business support & administration
                  </div>
                  <div className="section3Adm12ContentP">
                    <p>
                      Based across the UK, our specialists have a proven track
                      record in quickly finding you high standard business
                      support and administration professionals to meet your
                      needs.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="section3Adm2">
              <div className="section3Adm21">
                <div className="section3Adm11Logo">
                  <div className=" img-innerrrr">
                    <img
                      src="https://www.reed.com/_next/image?url=https%3A%2F%2Fimages.ctfassets.net%2F7qqwgjna58ct%2F1oRlAfiKncCfnWnfEBoiug%2F1f2b7e1a05efad25e1addf9d55540b04%2Feducation-square.jpg&w=3840&q=75"
                      alt=""
                    />
                  </div>
                </div>
                <div className="section3Adm11Content">
                  <div className="section3Adm21ContentHeading">Education</div>
                  <div className="section3Adm21ContentP">
                    <p>
                      Our national network of specialist education recruiters
                      work with over 10% of UK schools and understand the
                      complex requirements of the classroom.{" "}
                    </p>
                  </div>
                </div>
              </div>
              <div className="section3Adm22">
                <div className="section3Adm11Logo">
                  <div className=" img-innerrrr">
                    <img
                      src="https://www.reed.com/_next/image?url=https%3A%2F%2Fimages.ctfassets.net%2F7qqwgjna58ct%2F1DgoY7ZrHS3byRv12NTrYz%2Faa503b6cf46b7d1f36dcd0e7089d1931%2FHealth-and-care-hero-small.jpg&w=3840&q=75"
                      alt=""
                    />
                  </div>
                </div>
                <div className="section3Adm11Content">
                  <div className="section3Adm22ContentHeading">
                    Health & care
                  </div>
                  <div className="section3Adm22ContentP">
                    <p>
                      For over 6 years, CourseWorkZone has delivered a full
                      range of specialist health and care staffing solutions to
                      the public, private, and third sectors.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="section3Adm3">
              <div className="section3Adm31">
                <div className="section3Adm11Logo">
                  <div className=" img-innerrrr">
                    <img
                      src="https://www.reed.com/_next/image?url=https%3A%2F%2Fimages.ctfassets.net%2F7qqwgjna58ct%2F3sAh3SyhzC2vrHp63IW0pX%2F14d263de856ca825a32a66d3091f265c%2FScientifi-hero-small.jpg&w=3840&q=75"
                      alt=""
                    />
                  </div>
                </div>
                <div className="section3Adm11Content">
                  <div className="section3Adm31ContentHeading">
                    Scientific, food & FMCG
                  </div>
                  <div className="section32Adm11ContentP">
                    <p>
                      From analysis to quality assurance, the science
                      recruitment specialists at CourseWorkZone Scientific find
                      candidates to suit every position.
                    </p>
                  </div>
                </div>
              </div>
              <div className="section3Adm32">
                <div className="section3Adm11Logo">
                  <div className=" img-innerrrr">
                    <img
                      src="https://www.reed.com/_next/image?url=https%3A%2F%2Fimages.ctfassets.net%2F7qqwgjna58ct%2F4DQi3qEOvinwBYEMoatIl5%2Fd84cd9e3a077ed2bc0ffc1644243b46f%2Fmale-it-specialist-holds-laptop-and-discusses-work-with-female-server-picture-id802301404.jpg&w=3840&q=75"
                      alt=""
                    />
                  </div>
                </div>
                <div className="section3Adm11Content">
                  <div className="section3Adm32ContentHeading">Technology</div>
                  <div className="section3Adm32ContentP">
                    <p>
                      We are the IT recruitment specialists of choice for a
                      broad range of corporate, public sector and SME
                      organisations recruiting for both permanent and contract
                      positions.{" "}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="midbtn"></div>
        <div className="section4Container">
          <div className="section4">
            <div className="section4Heading">
              Develop and grow your business with our network of local
              specialists behind you
            </div>
            <div className="section4content">
              Working with CourseWorkZone, you don't have to go it alone. With
              over 6 years' experience in specialist recruitment, our dedicated
              consultants work with you to find talented professionals, to help
              your business flourish.
            </div>
            <div className="section4Stats">
              <div className="section4Stats1">
                <div className="section4Stats11">We have access to</div>
                <div className="section4Stats12">22m</div>
                <div className="section4Stats13">candidate CVs</div>
              </div>
              <div className="section4Stats2">
                <div className="section4Stats21">We have a</div>
                <div className="section4Stats22">4.7</div>
                <div className="section4Stats23">average rating on Google</div>
              </div>
              <div className="section4Stats3">
                <div className="section4Stats31">Nationwide we have</div>
                <div className="section4Stats32">33k</div>
                <div className="section4Stats33">temporary workers</div>
              </div>
            </div>
          </div>
        </div>

        {/* <div className="section5"> */}
        {/* <div className="section5Heading">
            <div className="section5HeadingH">Our latest success stories</div>
            <div className="section5HeadingBtn">
              <div className="btn1"></div>
              <div className="btn1"></div>
            </div>
          </div> */}
        {/* <div className="section5Content"> */}
        {/* <div className="section5Content1">
              <div className="section5ContentLogo">
                <img
                  src="https://www.reed.com/_next/image?url=https%3A%2F%2Fimages.ctfassets.net%2F7qqwgjna58ct%2F4w9s5wRmsDjSygWmP9eK3i%2F54f746fa1e4292a011f2593cec4998a2%2FMenzies_case_study_square_image.jpg&w=3840&q=75"
                  alt=""
                />
              </div>
              <div className="section5ContentP">
                <div className="section5ContentPHeadingSub">CLIENT</div>
                <div className="section5ContentPHeadingMain">
                  Menzies Wealth Management
                </div>
                <div className="section5ContentPContent">
                  Menzies Wealth Management used CourseWorkZone's expertise in
                  the insurance and financial services sector to source a
                  senior-level financial planner to expand their private client
                  advisory team.
                </div>
                <div className="section5ContentPBtn">
                  <button>Read More</button>
                </div>
              </div>
            </div> */}
        {/* <div className="section5Content2">
              <div className="section5ContentLogo">
                <img
                  src="https://www.reed.com/_next/image?url=https%3A%2F%2Fimages.ctfassets.net%2F7qqwgjna58ct%2F4w9s5wRmsDjSygWmP9eK3i%2F54f746fa1e4292a011f2593cec4998a2%2FMenzies_case_study_square_image.jpg&w=3840&q=75"
                  alt=""
                />
              </div>
              <div className="section5ContentP">
                <div className="section5ContentPHeadingSub">CLIENT</div>
                <div className="section5ContentPHeadingMain">
                  Menzies Wealth Management
                </div>
                <div className="section5ContentPContent">
                  Menzies Wealth Management used CourseWorkZone's expertise in
                  the insurance and financial services sector to source a
                  senior-level financial planner to expand their private client
                  advisory team.
                </div>
                <div className="section5ContentPBtn">
                  <button>Read More</button>
                </div>
              </div>
            </div> */}
        {/* <div className="section5Content3">
              <div className="section5ContentLogo">
                <img
                  src="https://www.reed.com/_next/image?url=https%3A%2F%2Fimages.ctfassets.net%2F7qqwgjna58ct%2F4w9s5wRmsDjSygWmP9eK3i%2F54f746fa1e4292a011f2593cec4998a2%2FMenzies_case_study_square_image.jpg&w=3840&q=75"
                  alt=""
                />
              </div>
              <div className="section5ContentP">
                <div className="section5ContentPHeadingSub">CLIENT</div>
                <div className="section5ContentPHeadingMain">
                  Menzies Wealth Management
                </div>
                <div className="section5ContentPContent">
                  Menzies Wealth Management used CourseWorkZone's expertise in
                  the insurance and financial services sector to source a
                  senior-level financial planner to expand their private client
                  advisory team.
                </div>
                <div className="section5ContentPBtn">
                  <button>Read More</button>
                </div>
              </div>
            </div> */}
        {/* <div className="sliderNav">
              <a href=".section5Content1"></a>
              <a href=".section5Content2"></a>
              <a href=".section5Content3"></a>
            </div> */}
        {/* </div> */}
        {/* </div> */}
        <div className="section6">
          <div className="section6Heading">
            <div className="section6HeadingL"></div>
            <div className="section6HeadingC">What's Happening</div>
          </div>
          <div className="section6Div">
            <div className="section61">
              <div className="section61Logo">
                <div className="img-2innerrrr">
                  <img
                    src="https://www.reed.com/_next/image?url=https%3A%2F%2Fimages.ctfassets.net%2F7qqwgjna58ct%2F4yfmWfZi5Iu16QfeUGVlUK%2F4d8eb1db1372d2f0f1292a6ebe7d5773%2FLanding_page_square_image_768x570-mob.jpg&w=3840&q=75"
                    alt=""
                  />
                </div>
              </div>

              {/* <div className="section3Adm11Logo">
                              <div className=' img-innerrrr'><img src="https://www.reed.com/_next/image?url=https%3A%2F%2Fimages.ctfassets.net%2F7qqwgjna58ct%2F3sAh3SyhzC2vrHp63IW0pX%2F14d263de856ca825a32a66d3091f265c%2FScientifi-hero-small.jpg&w=3840&q=75" alt="" /></div>
                        </div> */}
              <div className="section61SubH">TOOL BENEFITS</div>
              <div className="section61H">
                CourseWorkZone's UK salary guides 2023
              </div>
              <div className="section61P">
                Use our salary guides to compare the UK average salary by sector
                - a resource for both employers and employees. Download the 2023
                salary guides here.
              </div>
              <div className="section63F"> Read More</div>
            </div>
            <div className="section62">
              <div className="section62Logo">
                <div className="img-2innerrrr">
                  <img
                    src="https://www.reed.com/_next/image?url=https%3A%2F%2Fimages.ctfassets.net%2F7qqwgjna58ct%2F7mIrxymhghvUs13szB2ukc%2Fa9ecf47f76f9cb62fe4c06a6fe200a56%2Fbusiness-people-discussing-over-new-business-project-in-office.jpg_s_1024x1024_w_is_k_20_c_bPsBQIOqhTMiKR4Yg8fBtSxiX7n_zu3ZQ.jpg&w=3840&q=75"
                    alt=""
                  />
                </div>
              </div>
              {/* <img src="https://www.reed.com/_next/image?url=https%3A%2F%2Fimages.ctfassets.net%2F7qqwgjna58ct%2F7mIrxymhghvUs13szB2ukc%2Fa9ecf47f76f9cb62fe4c06a6fe200a56%2Fbusiness-people-discussing-over-new-business-project-in-office.jpg_s_1024x1024_w_is_k_20_c_bPsBQIOqhTMiKR4Yg8fBtSxiX7n_zu3ZQ.jpg&w=3840&q=75" alt="" /></div> */}
              <div className="section62SubH">EBOOK DIVERSITY & INCLUSION</div>
              <div className="section62H">
                Inclusion at Work 2022 – CIPD in partnership with CourseWorkZone
              </div>
              <div className="section62P">
                This survey report, in partnership with the CIPD, examines what
                UK employers are currently doing to improve inclusion and
                diversity (I&D) in their workplaces and the practices they have
                found to be effective.
              </div>
              <div className="section63F"> Read More</div>
            </div>
            <div className="section63">
              <div className="section63Logo">
                <img
                  src="https://www.reed.com/_next/image?url=https%3A%2F%2Fimages.ctfassets.net%2F7qqwgjna58ct%2Fki2mFIlNnidPyt07QreqC%2F2e74b76fba35b27b4bc38f5978997ca7%2FWinning_the_war_for_talent_-_square_image.jpg&w=3840&q=75"
                  alt=""
                />
              </div>
              <div className="section63SubH">Webinar Interviewing</div>
              <div className="section62H">
                Body talk with Judi James: an interviewer’s masterclass for
                winning talent
              </div>

              <div className="section63P">
                {" "}
                Judi James, leading communication and body language expert,
                explored the importance of body language and behaviour when
                conducting job interviews – both remote and face to face,
                offering hiring managers essential tips and advice.
              </div>
              <div className="section63F"> Read More</div>
            </div>
          </div>
        </div>

        <div className="section7">
          <div className="section7Wrapper">
            <div className="section7HEading">Find a CourseWorkZone office</div>
            <div className="section7Content">
              Our national coverage allows us to offer a recruitment service
              taylored to your needs, with accurate local market intelligence on
              salaries, competitors and the best professionals who can help your
              business thrive.
            </div>
            <div className="section7Lower">
              <div className="section7Lower1 section7Lower12">
                <p>East Anglia</p>
                <p>North West</p>
                <p>Wales</p>
              </div>
              <div className="section7Lower1 section7Lower12">
                <p>London</p>
                <p>South West</p>
                <p>
                  Yorkshire & <br />
                  Humberide
                </p>
              </div>
              <div className="section7Lower1 section7Lower12">
                <p>North East</p>
                <p>Scotland</p>
                <p>Northern Ireland</p>
              </div>
              <div className="section7Lower1  section7Lower122 ">
                <p>Midlands</p>
                <p>South West</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Job;
