import React, { useRef } from "react";
import emailjs from "@emailjs/browser";
import { Footer, Navbar } from "../../containers";
import "./contact.css";
import HeaderContact from "../../containers/headerContact/HeaderContact";

export const Contact = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();
    emailjs
      .sendForm(
        "service_4827scg",
        "template_d7c7r5c",
        form.current,
        "lcGODFCusPttwkCSV"
      )
      .then(
        (result) => {
          console.log(result.text);
          console.log("message sent");
          e.target.reset();
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  return (
    <>
      <Navbar />
      <HeaderContact />
      <div className="contactWrapper">
        <div className="mapImage">
          <img
            src="https://cwz-git-main-harshitasparrowtech-gmailcom.vercel.app/map.jpg"
            alt=""
          />
        </div>
      </div>

      <div className="form">
        <div className="formSection1">
          <form className="form" ref={form} onSubmit={sendEmail}>
            <div className="formwrap">
              <div className="formSection11 blax">
                <input
                  type="text"
                  className="firstHolder first"
                  placeholder=" Your Full Name"
                  name="user_name"
                />
                <input
                  type="mail"
                  className="firstHolder "
                  placeholder=" Email"
                  name="user_email"
                />
              </div>
              <div className="formSection11 blax">
                <input
                  className="firstHolder first"
                  placeholder="College Name"
                  type="text"
                  name="college_name"
                />
                <input
                  className="firstHolder"
                  placeholder="Course Name"
                  type="text"
                  name="course_name"
                />
              </div>

              <div className="formSection33">
                <textarea
                  className="additional"
                  placeholder=" Message"
                  name="message"
                />
              </div>
              <div className="formSection44">
                * Call Us 24/7 At 02039899999 Or Fill Out The Form Below To
                Receive A Free And Confidential Initial Consultation.
              </div>
              <input className="bluebtn" type="submit" value="Submit Now +" />
            </div>
          </form>
        </div>

        <div className="formSection11Heading"></div>

        <div className="formSection2">
          <div className="section2Wrapper">
            <div className="section2Heading">Contact Info 24/7</div>
            <div className="section212">
              <div className="section21">
                <div className="section21Logo">
                  <img
                    src="https://cwz-git-main-harshitasparrowtech-gmailcom.vercel.app/mask-group40@2x.png"
                    alt=""
                  />
                </div>
                <div className="section21Content">
                  <div className="section21ContentHeading zzzzzz">
                    Contact Phone
                  </div>
                  <div className="section21ContentText">02039899999</div>
                </div>
              </div>
              <div className="section22">
                <div className="section22Logo">
                  <img
                    src="https://cwz-git-main-harshitasparrowtech-gmailcom.vercel.app/mask-group41@2x.png"
                    alt=""
                  />
                </div>
                <div className="section22Content">
                  <div className="section22ContentHeading zzzzzz">
                    Contact Mail
                  </div>
                  <div className="section22ContentText">
                    ceo@courseworkzone.co.uk
                  </div>
                </div>
              </div>
              <div className="section23">
                <div className="section23Logo">
                  <img
                    src="https://cwz-git-main-harshitasparrowtech-gmailcom.vercel.app/mask-group42@2x.png"
                    alt=""
                  />
                </div>
                <div className="section23Content">
                  <div className="section23ContentHeading zzzzzz">
                    Contact Location
                  </div>
                  <div className="section23ContentText">
                    33 Ebury Street, London NW10AU
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Contact;
