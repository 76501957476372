import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import {
  Home,
  Service,
  Offers,
  About,
  Contact,
  Job,
  Admission,
  Privacy,
  PopupComponent,
} from "./pages";

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

const AppRoutes = () => (
  <Routes>
    <Route path="/" element={<App />} />
    <Route path="service" element={<Service />} />
    <Route path="offers" element={<Offers />} />
    <Route path="about" element={<About />} />
    <Route path="contact" element={<Contact />} />
    <Route path="home" element={<Home />} />
    <Route path="admission" element={<Admission />} />
    <Route path="job" element={<Job />} />
    <Route path="privacy" element={<Privacy />} />
    {/* Render PopupComponent when needed */}
    <Route path="thankyou" element={<PopupComponent />} />
  </Routes>
);

ReactDOM.render(
  <Router>
    <AppRoutes />
  </Router>,
  document.getElementById("root")
);
