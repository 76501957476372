import React from "react";
import { Footer, Navbar } from "../../containers";
import "./about.css";
import HeaderAbout from "../../containers/headerAbout/HeaderAbout";

const About = () => {
  return (
    <>
      <Navbar />
      <HeaderAbout />

      <div className="aboutUsSection1Wrapper">
        <div className="section1Contentt">
          <div className="section1Left">
            <div className="imgLeft">
              <div className="boxGreyy">
                <img
                  src="https://cwz-git-main-harshitasparrowtech-gmailcom.vercel.app/teams/michael-shores.jpeg"
                  alt=""
                />
              </div>
            </div>
          </div>
          <div className="section1Right">
            <div className="section1RightHeading">ABOUT US HISTORY</div>
            <div className="section1RightHeading2">
              Education is about learning skills and knowledge
            </div>
            <div className="section1RightContent">
              Education is a transformative process focused on acquiring skills,
              knowledge, and understanding. It empowers individuals to think
              critically, solve problems, and adapt to changing circumstances.
              Beyond academics, education cultivates creativity, fosters
              personal growth, and promotes social development, ultimately
              enriching lives and driving progress in society.
            </div>
            <div className="section1RightContentBottom">
               It fosters critical thinking and problem-solving abilities
              <br /> It drives progress and positive change in society. <br />
              It enhances social development and communication skills.
            </div>
            <div className="section1RightContentSignDiv">
              <div className="Circleimg">
                <div className="circlegg">
                  <img
                    src="https://cwz-git-main-harshitasparrowtech-gmailcom.vercel.app/teams/michael-shores.jpeg"
                    alt=""
                  />
                </div>
              </div>
              <div className="signimg">
                <img
                  src="https://cwz-git-main-harshitasparrowtech-gmailcom.vercel.app/mask-group19@2x.png"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="ourServiceWrapper">
        <div className="ourServiceContent">
          <div className="ourServiceTitle">
            <div className="ourServiceTitleUppper">EXCELLENT SERVICE</div>
            <div className="ourServiceTitleLower">
              Get Only New And Unique Boots Your CourseWorkZone Online Learning!
            </div>
          </div>
          <div className="ourServiceCardsWrapper">
            <div className="ourServiceCards">
              <div className="cardsCommonClass ourServiceCards1">
                <div className="logoCommonClass ourServiceCards1Logo">
                  <img
                    src="https://cwz-git-main-harshitasparrowtech-gmailcom.vercel.app/mask-group6@2x.png"
                    alt=""
                  />
                </div>
                <div className="ourServiceCardsHeadingCommon ourServiceCards1Heading">
                  Dissertation Writing Service
                </div>
                <div className="ourServiceCardsContentCommon ourServiceCards1Content">
                  Professional dissertation writing service offering expert
                  support for a successful dissertation. Experienced writers
                  ensure quality and timely delivery, providing tailored
                  assistance to meet individual needs.
                </div>
              </div>
              <div className="cardsCommonClass ourServiceCards2">
                <div className="logoCommonClass ourServiceCards2Logo">
                  <img
                    src="https://cwz-git-main-harshitasparrowtech-gmailcom.vercel.app/mask-group7@2x.png"
                    alt=""
                  />
                </div>
                <div className="ourServiceCardsHeadingCommon ourServiceCards2Heading">
                  Thesis Writing Help
                </div>
                <div className="ourServiceCardsContentCommon ourServiceCards2Content">
                  Expert thesis writing help provided by experienced
                  professionals. Personalized support and guidance for every
                  step of the thesis writing process, ensuring a well-crafted
                  and successful thesis.
                </div>
              </div>
              <div className="cardsCommonClass ourServiceCards3">
                <div className="logoCommonClass ourServiceCards3Logo">
                  <img
                    src="https://cwz-git-main-harshitasparrowtech-gmailcom.vercel.app/mask-group8@2x.png"
                    alt=""
                  />
                </div>
                <div className="ourServiceCardsHeadingCommon ourServiceCards3Heading">
                  Coursework Help
                </div>
                <div className="ourServiceCardsContentCommon ourServiceCards3Content">
                  Professional coursework service offering expert assistance for
                  academic success. Skilled writers deliver high-quality work,
                  ensuring excellent grades and achievement.
                </div>
              </div>
              <div className="cardsCommonClass ourServiceCards4">
                <div className="logoCommonClass ourServiceCards4Logo">
                  <img
                    src="https://cwz-git-main-harshitasparrowtech-gmailcom.vercel.app/mask-group21@2x.png"
                    alt=""
                  />
                </div>
                <div className="ourServiceCardsHeadingCommon ourServiceCards4Heading">
                  Expert Consultation
                </div>
                <div className="ourServiceCardsContentCommon ourServiceCards4Content">
                  CourseworkZone offers expert consultation for academic
                  success, providing specialized guidance and valuable insights
                  to excel in your coursework and achieve your goals.
                </div>
              </div>
              <div className="cardsCommonClass ourServiceCards5">
                <div className="logoCommonClass ourServiceCards5Logo">
                  <img
                    src="https://cwz-git-main-harshitasparrowtech-gmailcom.vercel.app/mask-group22@2x.png"
                    alt=""
                  />
                </div>
                <div className="ourServiceCardsHeadingCommon ourServiceCards5Heading">
                  Digital Library
                </div>
                <div className="ourServiceCardsContentCommon ourServiceCards5Content">
                  A digital library service providing online access to a vast
                  collection of books, articles, and multimedia resources,
                  offering convenience and flexibility for research, learning,
                  and information retrieval.
                </div>
              </div>
              <div className="cardsCommonClass ourServiceCards6">
                <div className="logoCommonClass ourServiceCards6Logo">
                  <img
                    src="https://cwz-git-main-harshitasparrowtech-gmailcom.vercel.app/mask-group23@2x.png"
                    alt=""
                  />
                </div>
                <div className="ourServiceCardsHeadingCommon ourServiceCards6Heading">
                  Formatting Guidance
                </div>
                <div className="ourServiceCardsContentCommon ourServiceCards6Content">
                  Formatting guidance service ensuring documents adhere to
                  required standards, such as APA, MLA, or Chicago. Expert
                  assistance to create polished and professional-looking papers,
                  saving time and effort.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="section1Admllll"
        style={{
          backgroundImage: `url('https://cwz-git-main-harshitasparrowtech-gmailcom.vercel.app/online-assignment.svg')`,
          backgroundSize: "cover",
        }}
      >
        <div className="section1AdmRight111">
          <div className="bgimcontent">
            <div className="bgimImg">
              <img
                src="https://cwz-git-main-harshitasparrowtech-gmailcom.vercel.app/mask-group20@2x.png"
                alt=""
              />
              <div className="bgimtext">
                Meet The Main Pioneers Of Education
              </div>
            </div>
          </div>

          <div className="peopleCardsWrapper">
            <div className="peopleCardsWrapperHeading">
              <div className="peopleCardsWrapperHeadingUpper">EXPERT TEAM</div>
              <div className="peopleCardsWrapperHeadingLower">
                Our Team Member
              </div>
            </div>
            <div className="peopleCards">
              <div className="peopleCards1">
                <div className="peopleCards1img">
                  <img
                    src="https://cwz-git-main-harshitasparrowtech-gmailcom.vercel.app/teams/michael-shores.jpeg"
                    alt=""
                  />
                </div>
                <div className="peopleCards1Content">
                  <div className="peopleCards1ContentHead">Michael Shores</div>
                  <div className="peopleCards1ContentBottom">
                    Courseworkzone Manager
                  </div>
                </div>
              </div>
              <div className="peopleCards1">
                <div className="peopleCards1img">
                  <img
                    src="https://cwz-git-main-harshitasparrowtech-gmailcom.vercel.app/teams/nicky-green.jpeg"
                    alt=""
                  />
                </div>
                <div className="peopleCards1Content ">
                  <div className="peopleCards1ContentHead">Nicky Green</div>
                  <div className="peopleCards1ContentBottom">
                    Courseworkzone Manager
                  </div>
                </div>
              </div>
              <div className="peopleCards1">
                <div className="peopleCards1img">
                  <img
                    src="https://cwz-git-main-harshitasparrowtech-gmailcom.vercel.app/teams/joan-gardiner.jpeg"
                    alt=""
                  />
                </div>
                <div className="peopleCards1Content">
                  <div className="peopleCards1ContentHead">Joan Gardiner</div>
                  <div className="peopleCards1ContentBottom">
                    Courseworkzone Manager
                  </div>
                </div>
              </div>
              <div className="peopleCards1">
                <div className="peopleCards1img">
                  <img
                    src="https://cwz-git-main-harshitasparrowtech-gmailcom.vercel.app/teams/dominic-withers.jpeg"
                    alt=""
                  />
                </div>
                <div className="peopleCards1Content">
                  <div className="peopleCards1ContentHead">Dominic Withers</div>
                  <div className="peopleCards1ContentBottom">
                    Courseworkzone Manager
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="section1AdmLeft"></div>
      </div>
      <div className="testimonial testimonialok">
        <div className="testiHead">
          <div className="heading">
            <h1>Testimonial</h1>
          </div>
          <div className="testiHImg"></div>
        </div>
        <div className="testiContent">
          <p>
            "CourseWorkZone's Exceptional Service Helped Me Secure Top Grades In
            My Coursework. The Experts Were Knowledgeable And Delivered On Time.
            Highly Recommended!" - Sarah P.
          </p>
        </div>
      </div>
      <div className="companiessss">
        <img
          src="https://cwz-git-main-harshitasparrowtech-gmailcom.vercel.app/colleges.jpg"
          alt=""
        />
      </div>
      <Footer />
    </>
  );
};

export default About;
