import React from "react";
import "./headerContact.css";
import people from "../../assets/people.webp";
const HeaderContact = () => {
  return (
    <div className="main">
      <div
        className="sectionContact"
        style={{ backgroundImage: `url(${people})`, display: "flex" }}
      >
        <div className="section67">
          <h1>Contact Us.</h1>
          {/* <h6>Looking for <span className='green'>help</span> from premier Assignment.</h6> */}
        </div>
        <div className="section2"></div>
      </div>
    </div>
  );
};

export default HeaderContact;
