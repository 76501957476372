import React from "react";
import holdin from "../../assets/holdin.webp";
import "./headerAdmission.css";

const HeaderAdmission = () => {
  return (
    <div className="main">
      <div
        className="sectionContact"
        style={{ backgroundImage: `url(${holdin})`, display: "flex" }}
      >
        <div className="section64">
          <h1>Admission Section</h1>
          <h6>
            Looking for <span className="green">help</span> in UK’s Top
            Universities
          </h6>
        </div>
      </div>
    </div>
  );
};

export default HeaderAdmission;
