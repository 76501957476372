import React, { Component, useRef } from "react";
import { Footer, Navbar } from "../../containers";
import "./admission.css";
import emailjs from "@emailjs/browser";
import HeaderAdmission from "../../containers/headerAssignment/HeaderAdmission";
import Fellowship from "../../assets/Fellowship.jpg";

class Assignment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      email: "",
      // Add more state variables for other form fields if needed
    };
    this.form = React.createRef();
  }

  handleInputChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };
  sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_lpp31yo",
        "template_fsnm06x",
        this.form.current,
        "8PULv25FT5q6-H9TK"
      )
      .then(
        (result) => {
          console.log(result.text);
          this.form.current.reset(); // Reset the form fields
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  scrollToAdmission4Section = () => {
    const admission4Sections =
      document.getElementsByClassName("admission4section");
    if (admission4Sections.length > 0) {
      admission4Sections[0].scrollIntoView({ behavior: "smooth" });
    }
  };

  render() {
    const checkboxStyle = {
      marginRight: "15px", // Adjust the value to increase or decrease space
    };

    return (
      <>
        <Navbar />
        <HeaderAdmission />
        <div className="admissionPage">
          <div className="admissioinWrapper">
            <div className="admission1section">
              <div className="admission1sectionContent">
                Our Admissions team is here to support your family through the
                application journey — from exploring The Global College, to
                applying for admission and joining the community. Please get in
                touch with any questions you may have and we will guide you
                through a personalized, straight-forward and timely process. The
                Global College’s main criteria for admission is the motivation,
                intellectual capacity and linguistic ability to complete a
                demanding IB Diploma Programme in English. The admissions
                decision is based on review of recent academic transcripts,
                information provided in the application, language proficiency
                and performance in the interview. To receive information about
                The Global College and its admissions process, please fill in
                the following form.
              </div>
              <div className="admission1sectionButton">
                <button onClick={this.scrollToAdmission4Section}>
                  Keep ME Informed
                </button>
              </div>
              <div
                className="admission2section"
                style={{ backgroundColor: "#f6f6f7" }}
              >
                <div className="admission2sectionHeading">
                  ADMISSION STEPS :
                </div>
                <div className="admission2sectionContenet">
                  <div className="admission2sectionContenet1">
                    <div className="admission2sectionContenet1Heading">
                      ENQUIRY
                    </div>
                    <div className="admission2sectionContenet1Content">
                      Before starting the admissions process, we strongly
                      recommend families to contact us to learn more about our
                      school. Information sessions (both virtual and face to
                      face) are organized regularly and are a perfect chance to
                      visit our campus to meet our Headmaster, Faculty members
                      and our Admissions and Pastoral Team.
                    </div>
                  </div>
                  <div className="admission2sectionContenet1">
                    <div className="admission2sectionContenet1Heading">
                      ONLINE APPLICATION
                    </div>
                    <div className="admission2sectionContenet1Content headingChanged">
                      The online application consists of six simple steps that
                      need to be completed in order to apply. Candidates will
                      need to submit information or documents related with the
                      following areas:
                      <li>
                        <b> Personal Information</b>
                      </li>
                      <li>
                        <b> Academic Profile</b>
                      </li>
                      <li>
                        <b>Languages and English level:</b> Candidates who are
                        not native speakers or have not studied in an English
                        speaking school for at least two complete academic
                        years, will have to submit an English certificate
                        equivalent to the Cambridge Advanced, IELTS 7 or
                        Duolingo minimum 120 points.
                      </li>
                      <li>
                        <b>Candidate’s interests</b> outside the classroom
                      </li>
                      <li>
                        <b>Letter of recommendation</b>
                      </li>
                      <li>
                        <b>Short video and essay</b>
                      </li>
                      Once you have registered,
                      <b>
                        you can access your application as many times as you
                        wish and rectify your details at any time before
                        submitting it.
                      </b>
                      <br /> Completing and submitting an application form is
                      not a commitment to a place by either party. The
                      Admissions Committee will review the application and
                      documents and will decide if candidates move to the next
                      step.
                    </div>
                  </div>
                  <div className="admission2sectionContenet1">
                    <div className="admission2sectionContenet1Heading">
                      ASSESSMENT
                    </div>
                    <div className="admission2sectionContenet1Content">
                      <b>Based line Assessment: </b>This is an assessment of the
                      student’s current levels across core aptitudes including
                      language and mathematics. We ask all students to take the
                      assessment as part of our global benchmarking process, and
                      it will be administered and proctored by our school.
                      <br />
                      <b>Interview:</b> The interview-based assessment is a
                      discussion between the candidate and our faculty members.
                      We want to learn more our potential student’s, their
                      talents, motivations and their future ambitions.
                    </div>
                  </div>
                  <div className="admission2sectionContenet1">
                    <div className="admission2sectionContenet1Heading">
                      ADMISSIONS COMMITTEE RESOLUTION
                    </div>
                    <div className="admission2sectionContenet1Content">
                      The Committee reviews all applications, documents and
                      interview reports to identify students with potential,
                      reflecting not only on their academic achievements, but
                      also on their capacity of initiative and their interest in
                      other extracurricular endeavors. If a positive admissions
                      decision is sent, families will then receive all the
                      relevant enrollment information in order to continue your
                      journey at The Global College.
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="admission3section"
              style={{ backgroundImage: `url(${Fellowship})`, display: "flex" }}
            >
              <div className="admission3sectionContent">
                <div className="admission3sectionContentWrapper">
                  <div className="admission3sectionheading">
                    SCHOLARSHIPS & GRANTS
                  </div>
                  <div className="admission3sectionContent2">
                    Equal chances to access the best quality education is at the
                    core of our values. We offer financial support and
                    scholarships for the best students. For more information,
                    please contact us at scholarships@theglobalcollege.com.
                  </div>
                </div>
              </div>
            </div>
            <div className="admission4section">
              <div className="admission4sectionLeft">
                <div className="admission4sectionLeftHeading">
                  KEEP ME INFORMED!
                </div>
                <div className="admission4sectionLeftContent">
                  If you are interested in studying the two-year International
                  Baccalaureate Diploma Programme in The Global College, please
                  fill out this form.
                </div>
              </div>
              <div className="admission4sectionRight">
                <form
                  ref={this.form}
                  onSubmit={this.sendEmail}
                  className="formAdmission"
                >
                  <label htmlFor="filler">I am </label>
                  <select
                    name="filler_name"
                    id="filler"
                    className="formfield1"
                    required
                  >
                    <option value="-- Select filler type --">
                      Select filler type
                    </option>
                    <option value="Mother">Mother</option>
                    <option value="Mother">Father</option>
                    <option value="Mother">Legal Guardian</option>
                    <option value="Mother">Student</option>
                  </select>
                  <label htmlFor="nname">Name</label>
                  <input
                    name="name_field"
                    type="Text"
                    required
                    id="nname"
                    className="formfield1"
                  />
                  <label htmlFor="mobile">Mobile No.</label>
                  <input
                    name="mobile"
                    required
                    id="mobile"
                    className="formfield1"
                  />
                  <label htmlFor="email">Enter your Email</label>
                  <input
                    type="mail"
                    required
                    id="email"
                    name="email"
                    className="formfield1"
                  />
                  <label htmlFor="countryCodee">Country of residence</label>
                  <select
                    id="countryCodee"
                    name="countryCodee"
                    className="formfield1"
                  >
                    <option value="Dominican Republic (+1)">
                      Dominican Republic (+1)
                    </option>
                    <option value="Barbados +1">Barbados (+1)</option>
                    <option value="Northern Mariana Island +1">
                      Northern Mariana Island (+1)
                    </option>
                    <option value="Montserrat (+1)">Montserrat (+1)</option>
                    <option value="Grenada (+1)">Grenada (+1)</option>
                    <option value="Bermuda (+1)">Bermuda (+1)</option>
                    <option value="Turks & Caicos (+1)">
                      Turks & Caicos (+1)
                    </option>
                    <option value="Sint Maarten (+1)">Sint Maarten (+1)</option>
                    <option value="Dominica (+1)">Dominica (+1)</option>
                    <option value="Puerto Rico (+1)">Puerto Rico (+1)</option>
                    <option value="Bahamas (+1)">Bahamas (+1)</option>
                    <option value="British Virgin Islands (+1)">
                      British Virgin Islands (+1)
                    </option>
                    <option value="Cayman Islands (+1)">
                      Cayman Islands (+1)
                    </option>
                    <option value="Canada (+1)">Canada (+1)</option>
                    <option value="Trinidad & Tobago (+1)">
                      Trinidad & Tobago (+1)
                    </option>
                    <option value="Antigua & Barbuda (+1)">
                      Antigua & Barbuda (+1)
                    </option>
                    <option value="St Kitts & Nevis (+1)">
                      St Kitts & Nevis (+1)
                    </option>
                    <option value="Anguilla (+1)">Anguilla (+1)</option>
                    <option value="Jamaica (+1)">Jamaica (+1)</option>
                    <option value="US Virgin Islands (+1)">
                      US Virgin Islands (+1)
                    </option>
                    <option value="St Lucia (+1)">St Lucia (+1)</option>
                    <option value="American Samoa (+1)">
                      American Samoa (+1)
                    </option>
                    <option value="St Vincent & the Grenadines (+1)">
                      St Vincent & the Grenadines (+1)
                    </option>
                    <option value="Guam (+1)">Guam (+1)</option>
                    <option value="United States (+1)">
                      United States (+1)
                    </option>
                    <option value="Kazakhstan (+7)">Kazakhstan (+7)</option>
                    <option value="Russia (+7)">Russia (+7)</option>
                    <option value="Egypt (+20)">Egypt (+20)</option>
                    <option value="South Africa (+27)">
                      South Africa (+27)
                    </option>
                    <option value="Netherlands (+31)">Netherlands (+31)</option>
                    <option value="Belgium (+32)">Belgium (+32)</option>
                    <option value="France (+33)">France (+33)</option>
                    <option value="Spain (+34)">Spain (+34)</option>
                    <option value="Hungary (+36)">Hungary (+36)</option>
                    <option value="Italy (+39)">Italy (+39)</option>
                    <option value="Romania (+40)">Romania (+40)</option>
                    <option value="Switzerland (+41)">Switzerland (+41)</option>
                    <option value="Austria (+43)">Austria (+43)</option>
                    <option value="United Kingdom (+44)">
                      United Kingdom (+44)
                    </option>
                    <option value="Denmark (+45)">Denmark (+45)</option>
                    <option value="Sweden (+46)">Sweden (+46)</option>
                    <option value="Norway (+47)">Norway (+47)</option>
                    <option value="Poland (+48)">Poland (+48)</option>
                    <option value="Germany (+49)">Germany (+49)</option>
                    <option value="Peru (+51)">Peru (+51)</option>
                    <option value="Mexico (+52)">Mexico (+52)</option>
                    <option value="Cuba (+53)">Cuba (+53)</option>
                    <option value="Argentina (+54)">Argentina (+54)</option>
                    <option value="Brazil (+55)">Brazil (+55)</option>
                    <option value="Chile (+56)">Chile (+56)</option>
                    <option value="Colombia (+57)">Colombia (+57)</option>
                    <option value="Venezuela (+58)">Venezuela (+58)</option>
                    <option value="Malaysia (+60)">Malaysia (+60)</option>
                    <option value="Australia (+61)">Australia (+61)</option>
                    <option value="Indonesia (+62)">Indonesia (+62)</option>
                    <option value="Philippines (+63)">Philippines (+63)</option>
                    <option value="New Zealand (+64)">New Zealand (+64)</option>
                    <option value="Singapore (+65)">Singapore (+65)</option>
                    <option value="Thailand (+66)">Thailand (+66)</option>
                    <option value="Japan (+81)">Japan (+81)</option>
                    <option value="South Korea (+82)">South Korea (+82)</option>
                    <option value="Vietnam (+84)">Vietnam (+84)</option>
                    <option value="China (+86)">China (+86)</option>
                    <option value="Turkey (+90)">Turkey (+90)</option>
                    <option value="India (+91)">India (+91)</option>
                    <option value="Pakistan (+92)">Pakistan (+92)</option>
                    <option value="Afghanistan (+93)">Afghanistan (+93)</option>
                    <option value="Sri Lanka (+94)">Sri Lanka (+94)</option>
                    <option value="Myanmar (+95)">Myanmar (+95)</option>
                    <option value="Iran (+98)">Iran (+98)</option>
                    <option value="South Sudan (+211)">
                      South Sudan (+211)
                    </option>
                    <option value="Western Sahara (+212)">
                      Western Sahara (+212)
                    </option>
                    <option value="Morocco (+212)">Morocco (+212)</option>
                    <option value="Algeria (+213)">Algeria (+213)</option>
                    <option value="Tunisia (+216)">Tunisia (+216)</option>
                    <option value="Libya (+218)">Libya (+218)</option>
                    <option value="Gambia (+220)">Gambia (+220)</option>
                    <option value="Senegal (+221)">Senegal (+221)</option>
                    <option value="Mauritania (+222)">Mauritania (+222)</option>
                    <option value="Mali (+223)">Mali (+223)</option>
                    <option value="Guinea (+224)">Guinea (+224)</option>
                    <option value="Ivory Coast (+225)">
                      Ivory Coast (+225)
                    </option>
                    <option value="Burkina Faso (+226)">
                      Burkina Faso (+226)
                    </option>
                    <option value="Niger (+227)">Niger (+227)</option>
                    <option value="Togo (+228)">Togo (+228)</option>
                    <option value="Benin (+229)">Benin (+229)</option>
                    <option value="Mauritius (+230)">Mauritius (+230)</option>
                    <option value="Liberia (+231)">Liberia (+231)</option>
                    <option value="Sierra Leone (+232)">
                      Sierra Leone (+232)
                    </option>
                    <option value="Ghana (+233)">Ghana (+233)</option>
                    <option value="Nigeria (+234)">Nigeria (+234)</option>
                    <option value="Chad (+235)">Chad (+235)</option>
                    <option value="Central African Republic (+236)">
                      Central African Republic (+236)
                    </option>
                    <option value="Cameroon (+237)">Cameroon (+237)</option>
                    <option value="Cape Verde (+238)">Cape Verde (+238)</option>
                    <option value="São Tomé and Príncipe (+239)">
                      São Tomé
                    </option>

                    <option value="Equatorial Guinea (+240)">
                      Equatorial Guinea (+240)
                    </option>
                    <option value="Gabon (+241)">Gabon (+241)</option>
                    <option value="Congo (+242)">Congo (+242)</option>
                    <option value="Democratic Republic of the Congo (+243)">
                      Democratic Republic of the Congo (+243)
                    </option>
                    <option value="Guinea-Bissau (+245)">
                      Guinea-Bissau (+245)
                    </option>
                    <option value="British Indian Ocean Territory (+246)">
                      British Indian Ocean Territory (+246)
                    </option>
                    <option value="Ascension Island (+247)">
                      Ascension Island (+247)
                    </option>
                    <option value="Seychelles (+248)">Seychelles (+248)</option>
                    <option value="Sudan (+249)">Sudan (+249)</option>
                    <option value="Rwanda (+250)">Rwanda (+250)</option>
                    <option value="Ethiopia (+251)">Ethiopia (+251)</option>
                    <option value="Djibouti (+253)">Djibouti (+253)</option>
                    <option value="Kenya (+254)">Kenya (+254)</option>
                    <option value="Tanzania (+255)">Tanzania (+255)</option>
                    <option value="Uganda (+256)">Uganda (+256)</option>
                    <option value="Burundi (+257)">Burundi (+257)</option>
                    <option value="Mozambique (+258)">Mozambique (+258)</option>
                    <option value="Zambia (+260)">Zambia (+260)</option>
                    <option value="Madagascar (+261)">Madagascar (+261)</option>
                    <option value="Réunion (+262)">Réunion (+262)</option>
                    <option value="Zimbabwe (+263)">Zimbabwe (+263)</option>
                    <option value="Namibia (+264)">Namibia (+264)</option>
                    <option value="Malawi (+265)">Malawi (+265)</option>
                    <option value="Lesotho (+266)">Lesotho (+266)</option>
                    <option value="Botswana (+267)">Botswana (+267)</option>
                    <option value="Swaziland (+268)">Swaziland (+268)</option>
                    <option value="Comoros (+269)">Comoros (+269)</option>
                    <option value="Saint Helena and Tristan da Cunha (+290)">
                      Saint Helena and Tristan da Cunha (+290)
                    </option>
                    <option value="Eritrea (+291)">Eritrea (+291)</option>
                    <option value="Aruba (+297)">Aruba (+297)</option>
                    <option value="Faroe Islands (+298)">
                      Faroe Islands (+298)
                    </option>
                    <option value="Greenland (+299)">Greenland (+299)</option>
                    <option value="Gibraltar (+350)">Gibraltar (+350)</option>
                    <option value="Portugal (+351)">Portugal (+351)</option>
                    <option value="Luxembourg (+352)">Luxembourg (+352)</option>
                    <option value="Ireland (+353)">Ireland (+353)</option>
                    <option value="Iceland (+354)">Iceland (+354)</option>
                    <option value="Albania (+355)">Albania (+355)</option>
                    <option value="Malta (+356)">Malta (+356)</option>
                    <option value="Cyprus (+357)">Cyprus (+357)</option>
                    <option value="Finland (+358)">Finland (+358)</option>
                    <option value="Bulgaria (+359)">Bulgaria (+359)</option>
                    <option value="Lithuania (+370)">Lithuania (+370)</option>
                    <option value="Latvia (+371)">Latvia (+371)</option>
                    <option value="Estonia (+372)">Estonia (+372)</option>
                    <option value="Moldova (+373)">Moldova (+373)</option>
                    <option value="Armenia (+374)">Armenia (+374)</option>
                    <option value="Belarus (+375)">Belarus (+375)</option>
                    <option value="Andorra (+376)">Andorra (+376)</option>
                    <option value="Monaco (+377)">Monaco (+377)</option>
                    <option value="San Marino (+378)">San Marino (+378)</option>
                    <option value="Ukraine (+380)">Ukraine (+380)</option>
                    <option value="Serbia (+381)">Serbia (+381)</option>
                    <option value="Kosovo (+383)">Kosovo (+383)</option>
                    <option value="Croatia (+385)">Croatia (+385)</option>
                    <option value="Slovenia (+386)">Slovenia (+386)</option>
                    <option value="Bosnia-Herzegovina (+387)">
                      Bosnia-Herzegovina (+387)
                    </option>
                    <option value="Macedonia (+389)">Macedonia (+389)</option>
                    <option value="Czech Republic (+420)">
                      Czech Republic (+420)
                    </option>
                    <option value="Slovakia (+421)">Slovakia (+421)</option>
                    <option value="Liechtenstein (+423)">
                      Liechtenstein (+423)
                    </option>
                    <option value="Falkland Islands (+500)">
                      Falkland Islands (+500)
                    </option>
                    <option value="Belize (+501)">Belize (+501)</option>
                    <option value="Guatemala (+502)">Guatemala (+502)</option>
                    <option value="El Salvador (+503)">
                      El Salvador (+503)
                    </option>
                    <option value="Honduras (+504)">Honduras (+504)</option>
                    <option value="Nicaragua (+505)">Nicaragua (+505)</option>
                    <option value="Costa Rica (+506)">Costa Rica (+506)</option>
                    <option value="Panama (+507)">Panama (+507)</option>
                    <option value="Saint Pierre and Miquelon (+508)">
                      Saint Pierre and Miquelon (+508)
                    </option>
                    <option value="Haiti (+509)">Haiti (+509)</option>
                    <option value="Guadeloupe (+590)">Guadeloupe (+590)</option>
                    <option value="Bolivia (+591)">Bolivia (+591)</option>
                    <option value="Guyana (+592)">Guyana (+592)</option>
                    <option value="Ecuador (+593)">Ecuador (+593)</option>
                    <option value="French Guiana (+594)">
                      French Guiana (+594)
                    </option>
                    <option value="Paraguay (+595)">Paraguay (+595)</option>
                    <option value="Martinique (+596)">Martinique (+596)</option>
                    <option value="Suriname (+597)">Suriname (+597)</option>
                    <option value="Uruguay (+598)">Uruguay (+598)</option>
                    <option value="Curaçao (+599)">Curaçao (+599)</option>
                    <option value="Caribbean Netherlands (+599)">
                      Caribbean Netherlands (+599)
                    </option>
                    <option value="Timor-Leste (+670)">
                      Timor-Leste (+670)
                    </option>
                    <option value="Australian Antarctic Territory (+672)">
                      Australian Antarctic Territory (+672)
                    </option>
                    <option value="Brunei (+673)">Brunei (+673)</option>
                    <option value="Nauru (+674)">Nauru (+674)</option>
                    <option value="Papua New Guinea (+675)">
                      Papua New Guinea (+675)
                    </option>
                    <option value="Tonga (+676)">Tonga (+676)</option>
                    <option value="Solomon Islands (+677)">
                      Solomon Islands (+677)
                    </option>
                    <option value="Vanuatu (+678)">Vanuatu (+678)</option>
                    <option value="Fiji (+679)">Fiji (+679)</option>
                    <option value="Palau (+680)">Palau (+680)</option>
                    <option value="Wallis and Futuna (+681)">
                      Wallis and Futuna (+681)
                    </option>
                    <option value="Cook Islands (+682)">
                      Cook Islands (+682)
                    </option>
                    <option value="Niue (+683)">Niue (+683)</option>
                    <option value="Samoa (+685)">Samoa (+685)</option>
                    <option value="Kiribati (+686)">Kiribati (+686)</option>
                    <option value="New Caledonia (+687)">
                      New Caledonia (+687)
                    </option>
                    <option value="Tuvalu (+688)">Tuvalu (+688)</option>
                    <option value="French Polynesia (+689)">
                      French Polynesia (+689)
                    </option>
                    <option value="Tokelau (+690) (+382)">
                      Tokelau (+690) (+382)
                    </option>
                    <option value="Micronesia (+691)">Micronesia (+691)</option>
                    <option value="Marshall Islands (+692)">
                      Marshall Islands (+692)
                    </option>
                    <option value="North Korea (+850)">
                      North Korea (+850)
                    </option>
                    <option value="Hong Kong (+852)">Hong Kong (+852)</option>
                    <option value="Macau (+853)">Macau (+853)</option>
                    <option value="Cambodia (+855)">Cambodia (+855)</option>
                    <option value="Laos (+856)">Laos (+856)</option>
                    <option value="Bangladesh (+880)">Bangladesh (+880)</option>
                    <option value="Maldives (+960)">Maldives (+960)</option>
                    <option value="Lebanon (+961)">Lebanon (+961)</option>
                    <option value="Jordan (+962)">Jordan (+962)</option>
                    <option value="Syria (+963)">Syria (+963)</option>
                    <option value="Iraq (+964)">Iraq (+964)</option>
                    <option value="Kuwait (+965)">Kuwait (+965)</option>
                    <option value="Saudi Arabia (+966)">
                      Saudi Arabia (+966)
                    </option>
                    <option value="Oman (+968)">Oman (+968)</option>
                    <option value="Palestine (+970)">Palestine (+970)</option>
                    <option value="United Arab Emirates (+971)">
                      United Arab Emirates (+971)
                    </option>
                    <option value="Israel (+972)">Israel (+972)</option>
                    <option value="Bahrain (+973)">Bahrain (+973)</option>
                    <option value="Qatar (+974)">Qatar (+974)</option>
                    <option value="Bhutan (+975)">Bhutan (+975)</option>
                    <option value="Mongolia (+976)">Mongolia (+976)</option>
                    <option value="Nepal (+977)">Nepal (+977)</option>
                    <option value="Tajikistan (+992)">Tajikistan (+992)</option>
                    <option value="Turkmenistan (+993)">
                      Turkmenistan (+993)
                    </option>
                    <option value="Azerbaijan (+994)">Azerbaijan (+994)</option>
                    <option value="Georgia (+995)">Georgia (+995)</option>
                    <option value="Kyrgyzstan (+996)">Kyrgyzstan (+996)</option>
                    <option value="Uzbekistan (+998)">Uzbekistan (+998)</option>
                  </select>
                  <label htmlFor="hearAbout">How did you hear about us ?</label>
                  <select
                    name="hearAbout"
                    className="formfield1"
                    id="hearAbout"
                  >
                    How did you hear about us
                    <option value="{{}}">{}</option>
                    <option value="Blog or digital/print publication">
                      Blog or digital/print publication
                    </option>
                    <option value="Educational Agent/Counselor">
                      Educational Agent/Counselor
                    </option>{" "}
                    <option value="Educational Fair/Event">
                      Educational Fair/Event
                    </option>{" "}
                    <option value="Embassy">Embassy</option>{" "}
                    <option value="Employer">Employer</option>{" "}
                    <option value="Google Ad">Google Ad</option>{" "}
                    <option value="IE Community">IE Community</option>
                    <option value="Internet Search">Internet Search</option>
                    <option value="Recommended by a current CWZ parent/student">
                      Recommended by a current CWZ parent/student
                    </option>
                    <option value="Recommended by a family considering CWZ">
                      Recommended by a family considering CWZ
                    </option>
                    <option value="Referral Website">Referral Website</option>
                    <option value="Social Media">Social Media</option>
                    <option value="Other">Other</option>
                  </select>
                  <label htmlFor="Studentname">Student Name</label>
                  <input
                    type="text"
                    required
                    id="Studentname"
                    name="student_name"
                    className="formfield1"
                  />
                  <label htmlFor="Studentmobile">Student Mobile No.</label>
                  <input
                    type="Text"
                    required
                    name="Studentmobile"
                    id="Studentmobile"
                    className="formfield1"
                  />
                  <label htmlFor="studentemail">Student's Email</label>
                  <input
                    type="mail"
                    required
                    name="studentemails"
                    id="studentemail"
                    className="formfield1"
                  />
                  <label htmlFor="dob">Date of Birth</label>
                  <input
                    type="date"
                    className="formfield1"
                    name="dob"
                    id="dob"
                  />
                  <label htmlFor="nationality">Nationality</label>
                  <select
                    id="nationality"
                    name="nationality"
                    className="formfield1"
                  >
                      <option value="Dominican Republic (+1)">
                      Dominican Republic (+1)
                    </option>
                    <option value="Barbados +1">Barbados (+1)</option>
                    <option value="Northern Mariana Island +1">
                      Northern Mariana Island (+1)
                    </option>
                    <option value="Montserrat (+1)">Montserrat (+1)</option>
                    <option value="Grenada (+1)">Grenada (+1)</option>
                    <option value="Bermuda (+1)">Bermuda (+1)</option>
                    <option value="Turks & Caicos (+1)">
                      Turks & Caicos (+1)
                    </option>
                    <option value="Sint Maarten (+1)">Sint Maarten (+1)</option>
                    <option value="Dominica (+1)">Dominica (+1)</option>
                    <option value="Puerto Rico (+1)">Puerto Rico (+1)</option>
                    <option value="Bahamas (+1)">Bahamas (+1)</option>
                    <option value="British Virgin Islands (+1)">
                      British Virgin Islands (+1)
                    </option>
                    <option value="Cayman Islands (+1)">
                      Cayman Islands (+1)
                    </option>
                    <option value="Canada (+1)">Canada (+1)</option>
                    <option value="Trinidad & Tobago (+1)">
                      Trinidad & Tobago (+1)
                    </option>
                    <option value="Antigua & Barbuda (+1)">
                      Antigua & Barbuda (+1)
                    </option>
                    <option value="St Kitts & Nevis (+1)">
                      St Kitts & Nevis (+1)
                    </option>
                    <option value="Anguilla (+1)">Anguilla (+1)</option>
                    <option value="Jamaica (+1)">Jamaica (+1)</option>
                    <option value="US Virgin Islands (+1)">
                      US Virgin Islands (+1)
                    </option>
                    <option value="St Lucia (+1)">St Lucia (+1)</option>
                    <option value="American Samoa (+1)">
                      American Samoa (+1)
                    </option>
                    <option value="St Vincent & the Grenadines (+1)">
                      St Vincent & the Grenadines (+1)
                    </option>
                    <option value="Guam (+1)">Guam (+1)</option>
                    <option value="United States (+1)">
                      United States (+1)
                    </option>
                    <option value="Kazakhstan (+7)">Kazakhstan (+7)</option>
                    <option value="Russia (+7)">Russia (+7)</option>
                    <option value="Egypt (+20)">Egypt (+20)</option>
                    <option value="South Africa (+27)">
                      South Africa (+27)
                    </option>
                    <option value="Netherlands (+31)">Netherlands (+31)</option>
                    <option value="Belgium (+32)">Belgium (+32)</option>
                    <option value="France (+33)">France (+33)</option>
                    <option value="Spain (+34)">Spain (+34)</option>
                    <option value="Hungary (+36)">Hungary (+36)</option>
                    <option value="Italy (+39)">Italy (+39)</option>
                    <option value="Romania (+40)">Romania (+40)</option>
                    <option value="Switzerland (+41)">Switzerland (+41)</option>
                    <option value="Austria (+43)">Austria (+43)</option>
                    <option value="United Kingdom (+44)">
                      United Kingdom (+44)
                    </option>
                    <option value="Denmark (+45)">Denmark (+45)</option>
                    <option value="Sweden (+46)">Sweden (+46)</option>
                    <option value="Norway (+47)">Norway (+47)</option>
                    <option value="Poland (+48)">Poland (+48)</option>
                    <option value="Germany (+49)">Germany (+49)</option>
                    <option value="Peru (+51)">Peru (+51)</option>
                    <option value="Mexico (+52)">Mexico (+52)</option>
                    <option value="Cuba (+53)">Cuba (+53)</option>
                    <option value="Argentina (+54)">Argentina (+54)</option>
                    <option value="Brazil (+55)">Brazil (+55)</option>
                    <option value="Chile (+56)">Chile (+56)</option>
                    <option value="Colombia (+57)">Colombia (+57)</option>
                    <option value="Venezuela (+58)">Venezuela (+58)</option>
                    <option value="Malaysia (+60)">Malaysia (+60)</option>
                    <option value="Australia (+61)">Australia (+61)</option>
                    <option value="Indonesia (+62)">Indonesia (+62)</option>
                    <option value="Philippines (+63)">Philippines (+63)</option>
                    <option value="New Zealand (+64)">New Zealand (+64)</option>
                    <option value="Singapore (+65)">Singapore (+65)</option>
                    <option value="Thailand (+66)">Thailand (+66)</option>
                    <option value="Japan (+81)">Japan (+81)</option>
                    <option value="South Korea (+82)">South Korea (+82)</option>
                    <option value="Vietnam (+84)">Vietnam (+84)</option>
                    <option value="China (+86)">China (+86)</option>
                    <option value="Turkey (+90)">Turkey (+90)</option>
                    <option value="India (+91)">India (+91)</option>
                    <option value="Pakistan (+92)">Pakistan (+92)</option>
                    <option value="Afghanistan (+93)">Afghanistan (+93)</option>
                    <option value="Sri Lanka (+94)">Sri Lanka (+94)</option>
                    <option value="Myanmar (+95)">Myanmar (+95)</option>
                    <option value="Iran (+98)">Iran (+98)</option>
                    <option value="South Sudan (+211)">
                      South Sudan (+211)
                    </option>
                    <option value="Western Sahara (+212)">
                      Western Sahara (+212)
                    </option>
                    <option value="Morocco (+212)">Morocco (+212)</option>
                    <option value="Algeria (+213)">Algeria (+213)</option>
                    <option value="Tunisia (+216)">Tunisia (+216)</option>
                    <option value="Libya (+218)">Libya (+218)</option>
                    <option value="Gambia (+220)">Gambia (+220)</option>
                    <option value="Senegal (+221)">Senegal (+221)</option>
                    <option value="Mauritania (+222)">Mauritania (+222)</option>
                    <option value="Mali (+223)">Mali (+223)</option>
                    <option value="Guinea (+224)">Guinea (+224)</option>
                    <option value="Ivory Coast (+225)">
                      Ivory Coast (+225)
                    </option>
                    <option value="Burkina Faso (+226)">
                      Burkina Faso (+226)
                    </option>
                    <option value="Niger (+227)">Niger (+227)</option>
                    <option value="Togo (+228)">Togo (+228)</option>
                    <option value="Benin (+229)">Benin (+229)</option>
                    <option value="Mauritius (+230)">Mauritius (+230)</option>
                    <option value="Liberia (+231)">Liberia (+231)</option>
                    <option value="Sierra Leone (+232)">
                      Sierra Leone (+232)
                    </option>
                    <option value="Ghana (+233)">Ghana (+233)</option>
                    <option value="Nigeria (+234)">Nigeria (+234)</option>
                    <option value="Chad (+235)">Chad (+235)</option>
                    <option value="Central African Republic (+236)">
                      Central African Republic (+236)
                    </option>
                    <option value="Cameroon (+237)">Cameroon (+237)</option>
                    <option value="Cape Verde (+238)">Cape Verde (+238)</option>
                    <option value="São Tomé and Príncipe (+239)">
                      São Tomé
                    </option>

                    <option value="Equatorial Guinea (+240)">
                      Equatorial Guinea (+240)
                    </option>
                    <option value="Gabon (+241)">Gabon (+241)</option>
                    <option value="Congo (+242)">Congo (+242)</option>
                    <option value="Democratic Republic of the Congo (+243)">
                      Democratic Republic of the Congo (+243)
                    </option>
                    <option value="Guinea-Bissau (+245)">
                      Guinea-Bissau (+245)
                    </option>
                    <option value="British Indian Ocean Territory (+246)">
                      British Indian Ocean Territory (+246)
                    </option>
                    <option value="Ascension Island (+247)">
                      Ascension Island (+247)
                    </option>
                    <option value="Seychelles (+248)">Seychelles (+248)</option>
                    <option value="Sudan (+249)">Sudan (+249)</option>
                    <option value="Rwanda (+250)">Rwanda (+250)</option>
                    <option value="Ethiopia (+251)">Ethiopia (+251)</option>
                    <option value="Djibouti (+253)">Djibouti (+253)</option>
                    <option value="Kenya (+254)">Kenya (+254)</option>
                    <option value="Tanzania (+255)">Tanzania (+255)</option>
                    <option value="Uganda (+256)">Uganda (+256)</option>
                    <option value="Burundi (+257)">Burundi (+257)</option>
                    <option value="Mozambique (+258)">Mozambique (+258)</option>
                    <option value="Zambia (+260)">Zambia (+260)</option>
                    <option value="Madagascar (+261)">Madagascar (+261)</option>
                    <option value="Réunion (+262)">Réunion (+262)</option>
                    <option value="Zimbabwe (+263)">Zimbabwe (+263)</option>
                    <option value="Namibia (+264)">Namibia (+264)</option>
                    <option value="Malawi (+265)">Malawi (+265)</option>
                    <option value="Lesotho (+266)">Lesotho (+266)</option>
                    <option value="Botswana (+267)">Botswana (+267)</option>
                    <option value="Swaziland (+268)">Swaziland (+268)</option>
                    <option value="Comoros (+269)">Comoros (+269)</option>
                    <option value="Saint Helena and Tristan da Cunha (+290)">
                      Saint Helena and Tristan da Cunha (+290)
                    </option>
                    <option value="Eritrea (+291)">Eritrea (+291)</option>
                    <option value="Aruba (+297)">Aruba (+297)</option>
                    <option value="Faroe Islands (+298)">
                      Faroe Islands (+298)
                    </option>
                    <option value="Greenland (+299)">Greenland (+299)</option>
                    <option value="Gibraltar (+350)">Gibraltar (+350)</option>
                    <option value="Portugal (+351)">Portugal (+351)</option>
                    <option value="Luxembourg (+352)">Luxembourg (+352)</option>
                    <option value="Ireland (+353)">Ireland (+353)</option>
                    <option value="Iceland (+354)">Iceland (+354)</option>
                    <option value="Albania (+355)">Albania (+355)</option>
                    <option value="Malta (+356)">Malta (+356)</option>
                    <option value="Cyprus (+357)">Cyprus (+357)</option>
                    <option value="Finland (+358)">Finland (+358)</option>
                    <option value="Bulgaria (+359)">Bulgaria (+359)</option>
                    <option value="Lithuania (+370)">Lithuania (+370)</option>
                    <option value="Latvia (+371)">Latvia (+371)</option>
                    <option value="Estonia (+372)">Estonia (+372)</option>
                    <option value="Moldova (+373)">Moldova (+373)</option>
                    <option value="Armenia (+374)">Armenia (+374)</option>
                    <option value="Belarus (+375)">Belarus (+375)</option>
                    <option value="Andorra (+376)">Andorra (+376)</option>
                    <option value="Monaco (+377)">Monaco (+377)</option>
                    <option value="San Marino (+378)">San Marino (+378)</option>
                    <option value="Ukraine (+380)">Ukraine (+380)</option>
                    <option value="Serbia (+381)">Serbia (+381)</option>
                    <option value="Kosovo (+383)">Kosovo (+383)</option>
                    <option value="Croatia (+385)">Croatia (+385)</option>
                    <option value="Slovenia (+386)">Slovenia (+386)</option>
                    <option value="Bosnia-Herzegovina (+387)">
                      Bosnia-Herzegovina (+387)
                    </option>
                    <option value="Macedonia (+389)">Macedonia (+389)</option>
                    <option value="Czech Republic (+420)">
                      Czech Republic (+420)
                    </option>
                    <option value="Slovakia (+421)">Slovakia (+421)</option>
                    <option value="Liechtenstein (+423)">
                      Liechtenstein (+423)
                    </option>
                    <option value="Falkland Islands (+500)">
                      Falkland Islands (+500)
                    </option>
                    <option value="Belize (+501)">Belize (+501)</option>
                    <option value="Guatemala (+502)">Guatemala (+502)</option>
                    <option value="El Salvador (+503)">
                      El Salvador (+503)
                    </option>
                    <option value="Honduras (+504)">Honduras (+504)</option>
                    <option value="Nicaragua (+505)">Nicaragua (+505)</option>
                    <option value="Costa Rica (+506)">Costa Rica (+506)</option>
                    <option value="Panama (+507)">Panama (+507)</option>
                    <option value="Saint Pierre and Miquelon (+508)">
                      Saint Pierre and Miquelon (+508)
                    </option>
                    <option value="Haiti (+509)">Haiti (+509)</option>
                    <option value="Guadeloupe (+590)">Guadeloupe (+590)</option>
                    <option value="Bolivia (+591)">Bolivia (+591)</option>
                    <option value="Guyana (+592)">Guyana (+592)</option>
                    <option value="Ecuador (+593)">Ecuador (+593)</option>
                    <option value="French Guiana (+594)">
                      French Guiana (+594)
                    </option>
                    <option value="Paraguay (+595)">Paraguay (+595)</option>
                    <option value="Martinique (+596)">Martinique (+596)</option>
                    <option value="Suriname (+597)">Suriname (+597)</option>
                    <option value="Uruguay (+598)">Uruguay (+598)</option>
                    <option value="Curaçao (+599)">Curaçao (+599)</option>
                    <option value="Caribbean Netherlands (+599)">
                      Caribbean Netherlands (+599)
                    </option>
                    <option value="Timor-Leste (+670)">
                      Timor-Leste (+670)
                    </option>
                    <option value="Australian Antarctic Territory (+672)">
                      Australian Antarctic Territory (+672)
                    </option>
                    <option value="Brunei (+673)">Brunei (+673)</option>
                    <option value="Nauru (+674)">Nauru (+674)</option>
                    <option value="Papua New Guinea (+675)">
                      Papua New Guinea (+675)
                    </option>
                    <option value="Tonga (+676)">Tonga (+676)</option>
                    <option value="Solomon Islands (+677)">
                      Solomon Islands (+677)
                    </option>
                    <option value="Vanuatu (+678)">Vanuatu (+678)</option>
                    <option value="Fiji (+679)">Fiji (+679)</option>
                    <option value="Palau (+680)">Palau (+680)</option>
                    <option value="Wallis and Futuna (+681)">
                      Wallis and Futuna (+681)
                    </option>
                    <option value="Cook Islands (+682)">
                      Cook Islands (+682)
                    </option>
                    <option value="Niue (+683)">Niue (+683)</option>
                    <option value="Samoa (+685)">Samoa (+685)</option>
                    <option value="Kiribati (+686)">Kiribati (+686)</option>
                    <option value="New Caledonia (+687)">
                      New Caledonia (+687)
                    </option>
                    <option value="Tuvalu (+688)">Tuvalu (+688)</option>
                    <option value="French Polynesia (+689)">
                      French Polynesia (+689)
                    </option>
                    <option value="Tokelau (+690) (+382)">
                      Tokelau (+690) (+382)
                    </option>
                    <option value="Micronesia (+691)">Micronesia (+691)</option>
                    <option value="Marshall Islands (+692)">
                      Marshall Islands (+692)
                    </option>
                    <option value="North Korea (+850)">
                      North Korea (+850)
                    </option>
                    <option value="Hong Kong (+852)">Hong Kong (+852)</option>
                    <option value="Macau (+853)">Macau (+853)</option>
                    <option value="Cambodia (+855)">Cambodia (+855)</option>
                    <option value="Laos (+856)">Laos (+856)</option>
                    <option value="Bangladesh (+880)">Bangladesh (+880)</option>
                    <option value="Maldives (+960)">Maldives (+960)</option>
                    <option value="Lebanon (+961)">Lebanon (+961)</option>
                    <option value="Jordan (+962)">Jordan (+962)</option>
                    <option value="Syria (+963)">Syria (+963)</option>
                    <option value="Iraq (+964)">Iraq (+964)</option>
                    <option value="Kuwait (+965)">Kuwait (+965)</option>
                    <option value="Saudi Arabia (+966)">
                      Saudi Arabia (+966)
                    </option>
                    <option value="Oman (+968)">Oman (+968)</option>
                    <option value="Palestine (+970)">Palestine (+970)</option>
                    <option value="United Arab Emirates (+971)">
                      United Arab Emirates (+971)
                    </option>
                    <option value="Israel (+972)">Israel (+972)</option>
                    <option value="Bahrain (+973)">Bahrain (+973)</option>
                    <option value="Qatar (+974)">Qatar (+974)</option>
                    <option value="Bhutan (+975)">Bhutan (+975)</option>
                    <option value="Mongolia (+976)">Mongolia (+976)</option>
                    <option value="Nepal (+977)">Nepal (+977)</option>
                    <option value="Tajikistan (+992)">Tajikistan (+992)</option>
                    <option value="Turkmenistan (+993)">
                      Turkmenistan (+993)
                    </option>
                    <option value="Azerbaijan (+994)">Azerbaijan (+994)</option>
                    <option value="Georgia (+995)">Georgia (+995)</option>
                    <option value="Kyrgyzstan (+996)">Kyrgyzstan (+996)</option>
                    <option value="Uzbekistan (+998)">Uzbekistan (+998)</option>
                  </select>
                  <label htmlFor="academicYear">
                    Academic Year of Interest
                  </label>
                  <select
                    name="academicYear"
                    className="formfield1"
                    id="academicYear"
                  >
                    <option value="{{}}">{}</option>
                    <option value="2023-2024">2024 - 2025</option>
                    <option value="2023-2024">2025 - 2026</option>
                    <option value="2023-2024">2026 - 2027</option>
                  </select>
                  <label htmlFor="interestIn">
                    Interest in Boarding/ Day School
                  </label>
                  <select
                    name="interestIn"
                    className="formfield1"
                    id="interestIn"
                  >
                    <option value="{{}}">{}</option>
                    <option value="Day School">Day School</option>
                    <option value="Boarding School">Boarding School</option>
                  </select>
                  <label htmlFor="currentSchool">Current School</label>
                  <input
                    type="text"
                    className="formfield1"
                    name="currentSchool"
                    id="currentSchool"
                  />
                  <label htmlFor="message">Message</label>
                  <input type="message" name="message" id="message" />
                  <p className="pppp">
                    Being minors, we will only contact the students if you
                    expressly authorized it by checking the corresponding box on
                    this form, and only to clarify information submitted within
                    the application or solve doubts about our educational
                    project, not about matters related with price or enrollment
                    details.
                  </p>
                  <label htmlFor="authorizeContact">
                    <input
                      type="checkbox"
                      name="authorizeContact"
                      id="authorizeContact"
                      style={checkboxStyle}
                    />
                    Check this box if you authorize The Course Work Zone to
                    contact the child directly, whose contact details have been
                    provided, with the sole purpose of matters related to the
                    application to The Course Work Zone and always under your
                    supervision.
                  </label>

                  <label style={{ marginTop: "20px" }}>
                    <input
                      type="checkbox"
                      name="authorized16"
                      id="authorizeContact16"
                      style={checkboxStyle}
                    />
                    Please check this box if you are over 16 years old and you
                    consent The College to process your data (even when this is
                    undertaken outside the EEA) and send you personalized
                    commercial information and/or information about its
                    activities
                  </label>
                  <button type="submit">Submit</button>
                </form>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </>
    );
  }
}

export default Assignment;
