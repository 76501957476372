import React from "react";
import { Footer, Navbar } from "../../containers";
import "./privacy.css";
import HeaderPrivacy from "../../containers/headerPrivacy/HeaderPrivacy";
const Privacy = () => {
  return (
    <>
      <Navbar />
      <HeaderPrivacy />
      <div className="privacyWrapper">
        <div className="privacyUpper">
          <h1 className="HeadingPrivacy">Privacy Policy of CourseWorkZone</h1>
        </div>
        <div className="privacyLower">
          <p>
            Privacy Policy of CourseWorkZone: Safeguarding your privacy is
            paramount to us. It's our responsibility to ensure you comprehend
            our privacy practices. Here's how we handle the information you
            provide: All student information remains within our company's
            purview; we strictly refrain from sharing it with any third-party
            websites. Details related to tutors are kept confidential. Payments
            are processed exclusively through PayPal; no part of our company
            deals with student or party credit card information apart from
            PayPal. Personal information is only disclosed in response to
            judicial inquiries or legal procedures for safeguarding our rights
            or complying with legal proceedings as outlined on our website.
            CourseWorkZone reserves the right to make changes, with updates
            reflected here; we urge students to check the privacy statement
            regularly to stay informed and avoid any potential risks.
          </p>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default Privacy;
