import React from "react";
import "./headerOffers.css";
import transformed from "../../assets/transformed.webp";
const HeaderOffers = () => {
  return (
    <div className="main">
      <div
        className="sectionContact"
        style={{ backgroundImage: `url(${transformed})`, display: "flex" }}
      >
        <div className="section65">
          <h1>Offers Page.</h1>
          <h6>Our package difference chart is below.</h6>
        </div>
        <div className="section2"></div>
      </div>
    </div>
  );
};

export default HeaderOffers;
