import React from 'react'
import { Footer, Navbar } from '../../containers';
import './service.css'
import HeaderServices from '../../containers/headerServices/HeaderServices';

const Service = () => {
  return (
    <div>
    <Navbar/>
    <HeaderServices/>
    <div className="ourServiceWrapper">
      <div className="ourServiceContent">
        <div className="ourServiceTitle">
            <div className="ourServiceTitleUppper">EXCELLENT SERVICE</div>
            <div className="ourServiceTitleLower">Get Only New And Unique Boots Your CourseWorkZone Online Learning!</div>
        </div>
        <div className="ourServiceCardsWrapper">
         <div className="ourServiceCards">
          <div className="cardsCommonClass ourServiceCards1">
            <div className="logoCommonClass ourServiceCards1Logo">
              <img src="https://cwz-git-main-harshitasparrowtech-gmailcom.vercel.app/mask-group6@2x.png" alt="" />
            </div>
            <div className="ourServiceCardsHeadingCommon ourServiceCards1Heading">Dissertation Writing Service</div>
            <div className="ourServiceCardsContentCommon ourServiceCards1Content">Professional dissertation writing service offering expert support for a successful dissertation. Experienced writers ensure quality and timely delivery, providing tailored assistance to meet individual needs.</div>
          </div>
          <div className="cardsCommonClass ourServiceCards2">
          <div className="logoCommonClass ourServiceCards2Logo"><img src="https://cwz-git-main-harshitasparrowtech-gmailcom.vercel.app/mask-group7@2x.png" alt="" /></div>
            <div className="ourServiceCardsHeadingCommon ourServiceCards2Heading">Thesis Writing Help</div>
            <div className="ourServiceCardsContentCommon ourServiceCards2Content">Expert thesis writing help provided by experienced professionals. Personalized support and guidance for every step of the thesis writing process, ensuring a well-crafted and successful thesis.</div>
          </div>
          <div className="cardsCommonClass ourServiceCards3">
          <div className="logoCommonClass ourServiceCards3Logo"><img src="https://cwz-git-main-harshitasparrowtech-gmailcom.vercel.app/mask-group8@2x.png" alt="" /></div>
            <div className="ourServiceCardsHeadingCommon ourServiceCards3Heading">Coursework Help</div>
            <div className="ourServiceCardsContentCommon ourServiceCards3Content">Professional coursework service offering expert assistance for academic success. Skilled writers deliver high-quality work, ensuring excellent grades and achievement.</div>
          </div>
          <div className="cardsCommonClass ourServiceCards4">
          <div className="logoCommonClass ourServiceCards4Logo"><img src="https://cwz-git-main-harshitasparrowtech-gmailcom.vercel.app/mask-group21@2x.png" alt="" /></div>
            <div className="ourServiceCardsHeadingCommon ourServiceCards4Heading">Expert Consultation</div>
            <div className="ourServiceCardsContentCommon ourServiceCards4Content">CourseworkZone offers expert consultation for academic success, providing specialized guidance and valuable insights to excel in your coursework and achieve your goals.</div>
          </div>
          <div className="cardsCommonClass ourServiceCards5">
          <div className="logoCommonClass ourServiceCards5Logo"><img src="https://cwz-git-main-harshitasparrowtech-gmailcom.vercel.app/mask-group22@2x.png" alt="" /></div>
            <div className="ourServiceCardsHeadingCommon ourServiceCards5Heading">Digital Library</div>
            <div className="ourServiceCardsContentCommon ourServiceCards5Content">A digital library service providing online access to a vast collection of books, articles, and multimedia resources, offering convenience and flexibility for research, learning, and information retrieval.</div>
          </div>
          <div className="cardsCommonClass ourServiceCards6">
          <div className="logoCommonClass ourServiceCards6Logo"><img src="https://cwz-git-main-harshitasparrowtech-gmailcom.vercel.app/mask-group23@2x.png" alt="" /></div>
            <div className="ourServiceCardsHeadingCommon ourServiceCards6Heading">Formatting Guidance</div>
            <div className="ourServiceCardsContentCommon ourServiceCards6Content">Formatting guidance service ensuring documents adhere to required standards, such as APA, MLA, or Chicago. Expert assistance to create polished and professional-looking papers, saving time and effort.</div>
          </div>
        </div>
        </div>
      </div>
    </div>

    <div className="section1Admllll" style={{ backgroundImage: `url('https://cwz-git-main-harshitasparrowtech-gmailcom.vercel.app/online-assignment.svg')` ,
        backgroundSize: 'cover', }}>
            <div className="section1AdmRight111" >
              <div className="bgimcontent">
              <div className="bgimImg">
                <img src="https://cwz-git-main-harshitasparrowtech-gmailcom.vercel.app/mask-group20@2x.png" alt="" />
                <div className="bgimtext">Meet The Main Pioneers Of Education</div>
              </div>
              </div>

              

              <div className="peopleCardsWrapper">
                <div className="peopleCardsWrapperHeading">
                  <div className="peopleCardsWrapperHeadingUpper">EXPERT TEAM</div>
                  <div className="peopleCardsWrapperHeadingLower">Our Team Member</div>
               </div>
               <div className="peopleCards">
                <div className="peopleCards1">
                  <div className="peopleCards1img">
                    <img src="https://cwz-git-main-harshitasparrowtech-gmailcom.vercel.app/teams/michael-shores.jpeg" alt="" />
                  </div>
                  <div className="peopleCards1Content">
                      <div className="peopleCards1ContentHead">Michael Shores</div>
                      <div className="peopleCards1ContentBottom">Courseworkzone Manager</div>
                  </div>
                </div>
                <div className="peopleCards1">
                  <div className="peopleCards1img">
                    <img src="https://cwz-git-main-harshitasparrowtech-gmailcom.vercel.app/teams/nicky-green.jpeg" alt="" />
                  </div>
                  <div className="peopleCards1Content">
                      <div className="peopleCards1ContentHead">Nicky Green</div>
                      <div className="peopleCards1ContentBottom">Courseworkzone Manager</div>
                  </div>
                </div>
                <div className="peopleCards1">
                  <div className="peopleCards1img">
                    <img src="https://cwz-git-main-harshitasparrowtech-gmailcom.vercel.app/teams/joan-gardiner.jpeg" alt="" />
                  </div>
                  <div className="peopleCards1Content">
                      <div className="peopleCards1ContentHead">Joan Gardiner</div>
                      <div className="peopleCards1ContentBottom">Courseworkzone Manager</div>
                  </div>
                </div>
                <div className="peopleCards1">
                  <div className="peopleCards1img">
                    <img src="https://cwz-git-main-harshitasparrowtech-gmailcom.vercel.app/teams/dominic-withers.jpeg" alt="" />
                  </div>
                  <div className="peopleCards1Content">
                      <div className="peopleCards1ContentHead">Dominic Withers</div>
                      <div className="peopleCards1ContentBottom">Courseworkzone Manager</div>
                  </div>
                </div>

               </div>
              </div>
                
            </div>
           
            <div className="section1AdmLeft"></div>
      </div>

    <div className="aboutUsSection1Wrapper">
      <div className="section1Contentt">
        <div className="section1Left">
          <div className="imgLeft">
            <div className="boxGreyy">
              <img src="https://cwz-git-main-harshitasparrowtech-gmailcom.vercel.app/teams/michael-shores.jpeg" alt="" />
            </div>
          </div>
        </div>
        <div className="section1Right">
          <div className="section1RightHeading">ABOUT US HISTORY</div>
          <div className="section1RightHeading2">Education is about learning skills and knowledge</div>
          <div className="section1RightContent">Education is a transformative process focused on acquiring skills, knowledge, and understanding. It empowers individuals to think critically, solve problems, and adapt to changing circumstances. Beyond academics, education cultivates creativity, fosters personal growth, and promotes social development, ultimately enriching lives and driving progress in society.</div>
          <div className="section1RightContentBottom">  It fosters critical thinking and problem-solving abilities<br/> It drives progress and positive change in society. <br /> It enhances social development and communication skills.</div>
          <div className="section1RightContentSignDiv">
            <div className="Circleimg">
              <div className="circlegg">
              <img src="https://cwz-git-main-harshitasparrowtech-gmailcom.vercel.app/teams/michael-shores.jpeg" alt="" />
              </div>
            </div>
            <div className="signimg">
              <img src="https://cwz-git-main-harshitasparrowtech-gmailcom.vercel.app/mask-group19@2x.png" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
      <div className="companiessss">
          <img src="https://cwz-git-main-harshitasparrowtech-gmailcom.vercel.app/colleges.jpg" alt="" />
        </div>










    {/* <HeaderServices/>
    <div className="our-services2">
        <div className="title9">
          <div className="expart-team1">EXCELLENT SERVICE</div>
          <b className="get-only-new-container2">
            <p className="about-us56">Get Only New And Unique Boots Your</p>
            <p className="about-us56">CourseWorkZone Online Learning!</p>
          </b>
        </div>
        <div className="innerServices1">
        <div className="service-13">
          <div className="dissertation-writing-service2" style={{ fontSize: '18px' }}>
            Dissertation Writing Service
          </div>
          <div className="duis-aliquet-vulputate6">{`Professional dissertation writing service offering expert support for a successful dissertation. Experienced writers ensure quality and timely delivery, providing tailored assistance to meet individual needs.`}</div>
        </div>
        <div className="service-23">
          <div className="thesis-writing-help2"style={{ fontSize: '18px' }}>Thesis Writing Help</div>
          <div className="duis-aliquet-vulputate6">{`Expert thesis writing help provided by experienced professionals. Personalized support and guidance for every step of the thesis writing process, ensuring a well-crafted and successful thesis.`}</div>
        </div>
        <div className="service-33">
          <div className="online-exam2"style={{ fontSize: '18px' }}>{`Coursework Help`}</div>
          <div className="duis-aliquet-vulputate8">{`Professional coursework service offering expert assistance for academic success. Skilled writers deliver high-quality work, ensuring excellent grades and achievement.`}</div>
        </div>
        </div>
        <div className='innerServices2'>
        <div className="service-41">
          <div className="expert-consultation"style={{ fontSize: '18px' }}>Expert Consultation</div>
          <div className="duis-aliquet-vulputate6">{`CourseworkZone offers expert consultation for academic success, providing specialized guidance and valuable insights to excel in your coursework and achieve your goals.`}</div>
        </div>
        <div className="service-5">
          <div className="digital-library"style={{ fontSize: '18px' }}>Digital Library</div>
          <div className="duis-aliquet-vulputate10">{`A digital library service providing online access to a vast collection of books, articles, and multimedia resources, offering convenience and flexibility for research, learning, and information retrieval.`}</div>
        </div>
        <div className="service-6">
          <div className="formatting-guidance"style={{ fontSize: '18px' }}>Formatting Guidance</div>
          <div className="duis-aliquet-vulputate6">{`Formatting guidance service ensuring documents adhere to required standards, such as APA, MLA, or Chicago. Expert assistance to create polished and professional-looking papers, saving time and effort.`}</div>
        </div>
        </div>
  </div>
    <div className="section3">
         <div className="bg">
          <img src="https://www.courseworkzone.co.uk/online-assignment.svg" alt="" />
          <div className="innerMeet">
            <img src="https://www.courseworkzone.co.uk/mask-group20@2x.png" alt="" />
            <b className="meet-the-main-container1">
            <p className="about-us5">Meet the main pioneers</p>
            <p className="about-us5">of education</p>
          </b>
          <div className="button3">
            <b className="read-more-about1">Read More About +</b>
          </div>
          </div>
        </div>
    </div> 
    <div className="title8">
          <div className="expart-team1">Expert Team</div>
          <b className="our-team-member1">Our Team Member</b>
    </div>
        <div className="team1">
          <div className="div40">
            <div className="child17" />
            <div className="mask-group6"><img className="mask-group6" src="https://www.courseworkzone.co.uk/teams/michael-shores.jpeg"/></div>
            <div className="elijah-charlotte1"> Michael Shores</div>
            <div className="educoda-menager4">CourseWorkZone Manager</div>
            <div className="child18" />
          </div>
          <div className="div42 ">
            <div className="child19 " />
            <div className="mask-group6" ><img className="mask-group6" src="https://www.courseworkzone.co.uk/teams/nicky-green.jpeg" alt=""/></div>
            <div className="elijah-charlotte1 ">Nicky Green</div>
            <div className="educoda-menager4">CourseWorkZone Manager</div>
            <div className="child18" />
          </div>
          <div className="div44">
            <div className="child21" />
            <div className="mask-group6" ><img className="mask-group6" src="https://www.courseworkzone.co.uk/teams/joan-gardiner.jpeg" alt=""/></div>
            <div className="elijah-charlotte1">Joan Gardiner</div>
            <div className="educoda-menager4">CourseWorkZone Manager</div>
            <div className="child18" />
          </div>
          <div className="div46">
            <div className="child23" />
            <div className="mask-group6" ><img className="mask-group6" src="https://www.courseworkzone.co.uk/teams/dominic-withers.jpeg" alt=""/></div>
            <div className="elijah-charlotte1"> Dominic Withers</div>
            <div className="educoda-menager4">CourseWorkZone Manager</div>
            <div className="child18" />
          </div>
        </div>
        <div className='wrappers'>
        <div className="about-us6">
          <div className="img2">
           <div className=' img-inner'>
           </div>
           <div className="img-child1" />
          </div>
        </div>
        <div className="text16">
        <div className="about-us-history1">About Us History</div>
          <div className="education-is-about1">
            Education is about learning <br/> skills and knowledge
          </div>
          <div className="phasellus-quis-interdum1">
          Education is a transformative process focused on acquiring skills, knowledge, and understanding. 
          It empowers individuals to think critically, solve problems, and adapt to changing circumstances. 
          Beyond academics, education cultivates creativity, fosters personal growth, and promotes social development,
           ultimately enriching lives and driving progress in society.
          </div>
          <div className="text-child11" />
          <img
            className="mask-group-icon39"
            alt=""
            src="https://www.courseworkzone.co.uk/mask-group19@2x.png"
          />
        </div>
        </div>
        <div className="companies">
          <img src="https://www.courseworkzone.co.uk/colleges.jpg" alt="" />
        </div> */}
        
        <Footer/>
    </div>
        
    
  )
}

export default Service