import React from "react";
import "./headerPrivacy.css";
import people from "../../assets/people.webp";
const HeaderPrivacy = () => {
  return (
    <div className="main">
      <div
        className="sectionContact"
        style={{ backgroundImage: `url(${people})`, display: "flex" }}
      >
        <div className="section65">
          <h1>Privacy Policies.</h1>
          <h6>
            Your Privacy Matters: Our Commitment to Protecting Your Information
          </h6>
        </div>
        <div className="section2"></div>
      </div>
    </div>
  );
};

export default HeaderPrivacy;
