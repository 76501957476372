import React from "react";
import "./headerAbout.css";
import jason from "../../assets/jason.jpg";

const HeaderAbout = () => {
  return (
    <div className="main">
      <div
        className="sectionContact"
        style={{ backgroundImage: `url(${jason})`, display: "flex" }}
      >
        <div className="section64">
          {/* <h6>Looking for <span className='green'>help</span> from premier Assignment.</h6> */}
        </div>
        <div className="section2">
          <img src={jason} alt="About Us" /> {/* Use the imported image */}
        </div>
      </div>
    </div>
  );
};

export default HeaderAbout;
