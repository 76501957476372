import React from "react";
import { useState, useRef } from "react";

const PopupComponent = ({ isOpen, closePopup, message }) => {
  const [isModelOpen, setIsModelOpen] = useState(false);
  const [popupMessage, setPopupMessage] = useState("");
  const handleClose = () => {
    setIsModelOpen(false);

    // Redirect to the home page
    window.history.pushState(null, "", "/");
    window.dispatchEvent(new Event("popstate"));
  };
  return (
    <div className="custom-model-main model-open">
      <div className="custom-model-inner">
        <div className="close-btn" onClick={handleClose}>
          ×
        </div>
        <div className="custom-model-wrap">
          <div className="pop-up-content-wrap">
            {"Form is submitted successfully"}
          </div>
        </div>
      </div>
      <div className="bg-overlay" onClick={closePopup}></div>
    </div>
  );
};

export default PopupComponent;
