import React from "react";
import { Footer, Navbar } from "../../containers";
import "./offers.css";
import HeaderOffers from "../../containers/headerOffers/HeaderOffers";

const Offers = () => {
  return (
    <>
      <Navbar />
      <HeaderOffers />
      <div className="tableContainer">
        <table className="tabletable">
          <tr>
            <th className="tableHeading" colspan="2">
              Budget Line
            </th>
          </tr>
          <tr className="trr">
            <td className="tablecontent">Writers Experience</td>
            <td className="tablecontent2">2 yrs</td>
          </tr>
          <tr className="trr">
            <td className="tablecontent">Co-ordination</td>
            <td className="tablecontent2">On E-Mail </td>
          </tr>
          <tr className="trr">
            <td className="tablecontent">Plagiarism </td>
            <td className="tablecontent2">less than 9%</td>
          </tr>
          <tr className="trr">
            <td className="tablecontent">Deadline </td>
            <td className="tablecontent2">30-45 Days</td>
          </tr>
          <tr className="trr">
            <td className="tablecontent">Project Manager Exp</td>
            <td className="tablecontent2">Less than 1 year</td>
          </tr>
          <tr className="trr">
            <td className="tablecontent">Proof Reader</td>
            <td className="tablecontent2">No </td>
          </tr>
          <tr className="trr">
            <td className="tablecontent">SPSS and Tools</td>
            <td className="tablecontent2">Extra Billing</td>
          </tr>
          <tr className="trr">
            <td className="tablecontent">Number of Revisions </td>
            <td className="tablecontent2">1-2 times</td>
          </tr>
          <tr className="trr">
            <td className="tablecontent">Intermediate Deliveries </td>
            <td className="tablecontent2">No</td>
          </tr>
          <tr className="trr">
            <td className="tablecontent">After Delivery Support</td>
            <td className="tablecontent2">No</td>
          </tr>{" "}
          <tr className="trr">
            <td className="tablecontent">Accessebility to VP & CEO </td>
            <td className="tablecontent2">No</td>
          </tr>
          <tr className="trr">
            <td className="tablecontent">Track record till now </td>
            <td className="tablecontent2">Mostly First Class</td>
          </tr>
          <tr className="trr">
            <td className="tablecontent">Client Satisfaction Ratio </td>
            <td className="tablecontent2">96.2%</td>
          </tr>
          <tr className="trr">
            <td className="tablecontent">Projects Completed till Date </td>
            <td className="tablecontent2">6380+</td>
          </tr>
        </table>
        <table className="twotable">
          <tr>
            <th className="tableHeading" colspan="2">
              Value Line
            </th>
          </tr>
          <tr className="trr">
            <td className="tablecontent">Writers Experience</td>
            <td className="tablecontent2">Min 2 yrs</td>
          </tr>
          <tr className="trr">
            <td className="tablecontent">Co-ordination</td>
            <td className="tablecontent2">Mail + Phone</td>
          </tr>
          <tr className="trr">
            <td className="tablecontent">Plagiarism </td>
            <td className="tablecontent2">less than 7%</td>
          </tr>
          <tr className="trr">
            <td className="tablecontent">Deadline </td>
            <td className="tablecontent2">15-30 Days</td>
          </tr>

          <tr className="trr">
            <td className="tablecontent">Project Manager Exp</td>
            <td className="tablecontent2">Less than 1 year +</td>
          </tr>
          <tr className="trr">
            <td className="tablecontent">Proof Reader</td>
            <td className="tablecontent2">Yes</td>
          </tr>
          <tr className="trr">
            <td className="tablecontent">SPSS and Tools</td>
            <td className="tablecontent2">Extra Billing</td>
          </tr>
          <tr className="trr">
            <td className="tablecontent">Number of Revisions </td>
            <td className="tablecontent2">Unlimited</td>
          </tr>
          <tr className="trr">
            <td className="tablecontent">Intermediate Deliveries </td>
            <td className="tablecontent2">Yes</td>
          </tr>
          <tr className="trr">
            <td className="tablecontent">After Delivery Support</td>
            <td className="tablecontent2">Up to 10 days</td>
          </tr>
          <tr className="trr">
            <td className="tablecontent">Accessebility to VP & CEO </td>
            <td className="tablecontent2">No</td>
          </tr>
          <tr className="trr">
            <td className="tablecontent">Track record till now </td>
            <td className="tablecontent2">Confirmed Merit</td>
          </tr>
          <tr className="trr">
            <td className="tablecontent">Client Satisfaction Ratio </td>
            <td className="tablecontent2">98%</td>
          </tr>
          <tr className="trr">
            <td className="tablecontent">Projects Completed till Date </td>
            <td className="tablecontent2">9651+</td>
          </tr>
        </table>
        <table className="threetable">
          <tr>
            <th className="tableHeading" colspan="2">
              Highline
            </th>
          </tr>
          <tr className="trr">
            <td className="tablecontent">Writers Experience</td>
            <td className="tablecontent2">Min 10yr+</td>
          </tr>
          <tr className="trr">
            <td className="tablecontent">Co-ordination</td>
            <td className="tablecontent2">Desicated Line</td>
          </tr>
          <tr className="trr">
            <td className="tablecontent">Plagiarism </td>
            <td className="tablecontent2">less than 5%</td>
          </tr>
          <tr className="trr">
            <td className="tablecontent">Deadline </td>
            <td className="tablecontent2">15 Days +</td>
          </tr>

          <tr className="trr">
            <td className="tablecontent">Project Manager Exp</td>
            <td className="tablecontent2">3yr - 5yr</td>
          </tr>
          <tr className="trr">
            <td className="tablecontent">Proof Reader</td>
            <td className="tablecontent2">Experienced</td>
          </tr>
          <tr className="trr">
            <td className="tablecontent">SPSS and Tools</td>
            <td className="tablecontent2">Complementary</td>
          </tr>
          <tr className="trr">
            <td className="tablecontent">Number of Revisions </td>
            <td className="tablecontent2">Unlimited</td>
          </tr>
          <tr className="trr">
            <td className="tablecontent">Intermediate Deliveries </td>
            <td className="tablecontent2">Daily updates</td>
          </tr>
          <tr className="trr">
            <td className="tablecontent">After Delivery Support</td>
            <td className="tablecontent2">Up to 1 yr</td>
          </tr>
          <tr className="trr">
            <td className="tablecontent">Accessebility to VP & CEO </td>
            <td className="tablecontent2">Yes</td>
          </tr>
          <tr className="trr">
            <td className="tablecontent">Track record till now </td>
            <td className="tablecontent2">Mostly Distinctions</td>
          </tr>
          <tr className="trr">
            <td className="tablecontent">Client Satisfaction Ratio </td>
            <td className="tablecontent2">99.4%</td>
          </tr>
          <tr className="trr">
            <td className="tablecontent">Projects Completed till Date </td>
            <td className="tablecontent2">941 +</td>
          </tr>
        </table>
        {/* <div class="circle1"></div> */}
      </div>
      <Footer />
    </>
  );
};

export default Offers;
